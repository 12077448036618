
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footer-widget" style={{backgroundColor:"rgb(236, 220, 220)",color:"black",width:"100%",marginLeft:"-0px"}}>
        <div className="container-fluid line-container">
          <div className="row" style={{padding:"30px"}}>
            {/* <div className="sarga_footer_top"> */}
              <div className="col-md-4"  >
                <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/FINAL+New+logo+black.jpg" style={{ maxWidth: "inherit", marginBottom: "15px",height:"17vh" }} width="150px" alt="Footer Logo" />
                <div className="work-with-us" >
                  <div className="widget-title" >
                    <h3 className="title clr-yellow">SITE ADDRESS</h3>
                  </div>
                  <p className="footer_btm_br text-left pb5">TUDA TOWERS,<br /> Air Bypass Road,<br />Annamaiah Circle, <br />Tirupati-517501,<br/> Andhra Pradesh-India.</p>
                </div>
              </div>
              <div className="col-md-4">
              <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/tudalogo1.jpeg" style={{ maxWidth: "inherit", marginBottom: "13px",height:"17vh" }} width="150px" alt="Footer Logo" />
                <div className="widget-title pt15">
                  <h3 className="title clr-yellow">CORPORATE ADDRESS</h3>
                </div>
                <p className="footer_btm_br text-left">Tirupati Urban Development Authority (TUDA)  
                <br />Tuda Office Road,<br />Tirupati-517501. <br />Andhra Pradesh - India <br/>
                
                  Customer care <br />
                  +91 0877-222 5730
                </p>
              </div>
              <div className="col-md-4" >
                <div className="widget-title pt30"  style={{padding:"0px"}}>
                  <h3 className="title clr-yellow" >QUICK LINKS</h3>
                
                
                  <ul>
                    <li>
                      <a routerlink="/" href="/">
                        HOME
                      </a>
                    </li>
                    <li>
                      <a routerlink="/about" href="/about">
                        ABOUT PROJECT
                      </a>
                    </li>
                    <li>
                      <a routerlink="/masterplans" href="/masterplans">
                        MASTER PLANS &amp; FLOOR PLANS
                      </a>
                    </li>
                    <li>
                      <a routerlink="/amenities" href="/amenities">
                        AMENITIES &amp; SPECIFICATIONS
                      </a>
                    </li>
                    <li>
                      <a href="/documents" target="_blank">
                       DOCUMENTS
                      </a>
                    </li>
                    <li>
                      <a routerlink="/contact" href="/contact">
                        CONTACT US
                      </a>
                    </li>
                    <div >
                      {/* <a routerlink="/contact" href="/contact">
                        CONTACT US
                      </a> */}
                      <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/HDFC+bank.jpeg" style={{width:"180px",padding:"30px"}}></img>
                      <h6 style={{color:"blue",marginLeft:"30px"}}>We bank with HDFC</h6>
                    </div>
                  </ul>
                  </div>
               
              </div>
              <div className='row'>
              <div className="col-md-12" >
                <div>
                  {/* <div className="widget-title posr">
                    <h3 className="title cby clr-">DEVELOPED BY</h3>
                    <p className="online" style={{ top: "15px" }}></p>
                  </div> */}
                  <p className="pt-5 text-left">
                  <a href="https://www.sssbi.com/"> <img src="images/footer_logo.gif" style={{ height: "60px",width:"60px",backgroundColor:"pink",mixBlendMode:"multiply" }} alt="" /></a>
                  </p>
                </div>
                <div className="widget-title posr" >
                  <label>Powered By</label>
               <a href="https://www.sssbi.com/"><h5 className="title cby clr-" >Srinivasan Software Solutions Pvt Ltd</h5></a> 
                    <p className="online" ></p>
                  </div>

                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="line-one ltwhite-one"></div>
          <div className="line-two ltwhite-two"></div>
          <div className="line-three ltwhite-three"></div>
          <div className="line-four ltwhite-four"></div>
          <div className="line-five ltwhite-five"></div>
          </div>
      {/* </div> */}
    </footer>
  );
};

export default Footer;
