import React from 'react'
import './App.css';
const Explore = () => {
  return (
    <div>
      <img src="/images/tuda aerial face hill 200722.png" alt='explore' className='explore'
       style={{width:"1500px",height:"550px",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
    </div>
  )
}

export default Explore;
