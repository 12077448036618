import React from 'react'
import Footer from './Footer';
import './Conditions.css';
import Table from 'react-bootstrap/Table';


const Conditions = () => {
  return (
    <div>
    <div style={{backgroundColor:"#f1eaea",margin:"0%",width:"100%",marginRight:"30%",marinLeft:"10%"}}>
    <div style={{backgroundColor:"#635b5b",margin:"2px",padding:"2%",width:"90%",marginRight:"10%",marginLeft:"5%",
    color:"white",fontSize:"16px"}}>  
    <h1>TERMS & CONDITIONS</h1>
    <div>
    <details>
      <summary>
<h5 style={{marginTop: "-24px",
  paddingLeft: "20px"}}> GENERAL TERMS & CONDITIONS</h5> </summary>
        <a  aria-expanded="false" classNameName="firsttop collapsed" data-parent="#accordion" data-toggle="collapse"
        href="#collapseOne" role="button">
            <i  className="more-less glyphicon glyphicon-plus"></i> General Terms &amp; Conditions </a>
      <div  className="col-md-12 lg-disclaimer ph30 sscroll scroll-me"><div  className="faq fontclr scroll-window">
      <div  className="panel-body tc-points"><ol >
      <li >Payment of booking advance towards booking a flat in TUDA  can be made only through 
      online process on <a  href="https://www.tudaap.in/" target="blank">https://www.tudaap.in</a> &nbsp; (or) &nbsp;<a 
       href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a>  website </li>
      <li >Booking of a flat is strictly based on realisation of booking advance by TUDA on a auction basis. Auction at an increment of Rs 10 </li>
      <li >Following individuals / persons are eligible to book the flat in TUDA : <p  className="pl15 lh15">
      <span  className="clr-yellow mln15">i.</span> Indian Nationals and NRIs (sole or joint applicant)</p>
      <p  className="pl15 lh15"><span  className="clr-yellow mln15">ii.</span> Legal Entities 
      registered under Indian Companies Act ( The person applying on behalf of company should furnish the Board Resolution to 
      purchase the flat and for entering in to such deeds, acts and other documents related to at the time of KYC ) .</p></li>
      <li >Carpet Area of the flat means the net useable floor area of a flat, excluding the area covered by external walls, 
      areas under service shafts, exclusive balcony or verandah area, exclusive open terrace area, but includes the area covered by the 
      internal partition walls of the flat .</li>
      <li >Saleable Area of the Flat includes - Carpet Area, balcony area, external
      walls of the flat and pro-rata share of the common areas of the project. </li><li >“Sale Consideration” amount is inclusive
        of Base Price of the saleable area, Car Parking Charges,  Preferred Location Charges (East Facing &amp; 
        Floor Rise from 6th level onwards), Infrastructure Development Cost. </li><li >“Total Cost of the Flat” includes Sale 
        Consideration and applicable taxes. </li><li >“Total Amount Payable” includes Total Cost of the Flat, Corpus Fund &amp; 
        Maintenance Charges with applicable taxes. </li><li >Refer to <a href="#payment-terms">payment terms</a> &nbsp;
for detailed pricing information. </li>
        <li > It is advised that the applicant shall make himself/herself aware of the following before proceeding for the payment.
        <p  className="pl20 lh15"><span  className="clr-yellow mln15">i.</span> Complete project details 
        mentioned on the TUDA  website, including the details of the area of specific flat, Sale Consideration, Total Cost of the Flat, 
        Total Amount Payable. </p><p  className="pl20 lh15"><span  className="clr-yellow mln15">ii.</span> Payment 
        Milestones from booking advance till Registration of Conveyance / sale deed.</p><p  className="pl20 lh15">
        <span  className="clr-yellow mln15">iii.</span> Regular updates at <a  href="https://www.tudaap.in/" 
        target="blank">https://www.tudaap.in/</a>  &nbsp; (or) &nbsp;<a  href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a>  website</p></li><li > Registration Fee &amp; Stamp Duty shall be 
        borne by the Allottee and payable to the registration department directly at the time of registration of Agreement for Sale &amp; 
        Conveyance / Sale Deed. </li><li > Booking advance shall be treated as provisional only, until the ‘Agreement for Sale’ is
          executed within 30 days (45 days in case NRI is a Sole applicant / Joint applicant) from the date of payment of booking advance. </li>
          <li > It is the responsibility of the provisional Allottee(s) to complete the KYC formalities, which is a precondition 
          for executing the ‘Agreement for Sale’. The Applicant with provisional allotment, shall visit the TUDA ,TUDA  office in Tirupati, 
          to complete the KYC formalities, with all necessary documents as required and communicated by the TUDA . </li>
          <li > If any provisional Allottee(s) is(are) found to be 
          fraudulent after booking the flat, the booking shall be cancelled, and the entire booking advance shall be forfeited and 
          appropriate action shall be undertaken as per applicable laws. The provisional Allottee after cancellation shall not have 
          any claim or right on the flat, whatsoever the case may be. </li><li > Submission of the Application Form 
          and payment of booking advance shall not confer any right, title, interest in the property and the booking shall only be 
          treated as provisional till the stage of registration of Conveyance / Sale deed. </li><li > Provisional 
          Allottee or General Power of Attorney (registered) holder, on behalf of provisional allottee, shall be present at the time of 
          Registration of ‘Agreement for Sale’ document. NRIs may execute Agreement for sale with the help of Registered General Power of 
          Attorney obtained through Apostille attestation. </li><li > Correction required (if any) in the spelling of 
          provisional Allottee’s name as per his/her Aadhaar / Passport / CIN details will be permitted before executing ‘Agreement for Sale’. </li>
          <li > The flat booked by the provisional Allottee(s) shall be registered in his/her/their/ company name only as per 
          the details provided at the time of booking. Name Change or Transfer of flat to other third parties is not permitted . However, the flat can only be Transferred/ registered to any eligible individual / entity i.e., blood relatives or legal heirs after payment of 85% 
          of the Total Cost of the Flat but before execution of the Conveyance / sale deed. Such transfer is accepted upon payment of additional
            amount of Two Percent (2.0%) of the Sale Consideration and applicable taxes to TUDA. </li><li > TUDA shall
            periodically intimate through Invoice to the Allottee(s) about the amount payable as per Payment Terms and Allottee(s) shall
              make payment of each instalment within 15 (fifteen) days from the date of Invoice. Allottees understand that timely payment of 
              instalment is of essence and any delay will be liable for interest. </li><li > In case, Allottee(s) fails to make
              payment made by TUDA, despite having been issued notice in that regard the Allottee(s) shall 
              be liable to pay interest to TUDA on the unpaid amount at the rate as per prevailing SBI prime lending rate plus two percent
                for the delayed period. </li>
                
              
                 
                <Table striped bordered hover>
                
    <thead>
    
      <tr>
     
        <th><li style={{color:"white"}}></li></th>
        <th> CANCELLATION/ SURRENDER/WITHDRAWAL REFUNDS</th>
        
      </tr>
      
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>a)  For non –payment of the price with in stipulated time, the allotment will be cancelled without any intimation of whatsoever nature.  All the payments by the applicant shall be made with in the stipulated time.</td>
        
      </tr>
      <tr>
        <td></td>
        <td>b) For the purpose of prompt accounting  of the applicant, he /she may intimate the payment particulars as and when made </td>
        
      </tr>
      <tr>
        <td></td>
        <td >
c) The allotment is also liable for cancellation for violation of any other terms and conditions as contained herein or as may be communicated from time to time.</td>
        
      </tr>

      <tr>
        <td></td>
        <td>d) withdrawal or surrender of allotment by the applicant amounts to cancellation and forfeiture of EMD etc. as detailed below.
</td>
        
      </tr>


      <tr>
        <td></td>
        <td>e) THE ALLOTMENT SHALL BE CANCELLED.</td>
        </tr>
        <tr>
          <td></td>
          <td> i)If the allottee refuses the flat allotted within 7 days of issue of provisional allotment letter.</td>

         
        </tr>
         
<tr>
<td></td>
<td>ii) If the allottee fails to pay the amount due, on account of balance sale price or instalment or arrears of instalment with penal interest or Registration charges with in the time allowed by the authority.</td>
</tr>
         


        
      
      <tr>
        <td></td>
        <td>f) THE FOLLOWING CONSEQUENCES FOLLOW THE CANCELLATION OF THE ALLOTMENT.</td>

</tr>

<tr>
<td></td>
<td> i)  Where the allotment is cancelled under condition No.21 (e) (i) entire EMD shall be forfeited.</td>
</tr>
      
      <tr>
        <td></td>
        <td>ii) Where the allotment is cancelled under condition No.21(e) (ii) the entire EMD and 25% of the amount till then paid shall be forfeited.</td>
      </tr>
       




        

        
      

<tr>
<td></td>
<td>TRANSFER OF ALLOTMENT</td>
</tr>
  <tr><td></td><td>i)Application for transfer of allotment of plot shall be entertained in the event of mentioning the name of the nominee. </td>
  </tr>

<tr>
<td></td>
<td>
ii)   the applicant has to mention the nominee particulars   in the application itself to transfer the plot in the name of nominee in case he chooses so. 
</td>
</tr>
    
    </tbody>

  </Table>
 
                
                
                
                
                
                
                
                
                
                
               {/* <li > Allottee(s) shall
                    have the right to cancel/withdraw his/her allotment, provided that where the allottee(s) proposes to cancel/withdraw from the 
                    project without any fault of TUDA, TUDA herein is entitled to forfeit the full booking advance paid  and 10% of installment amount till then paid for the allotment. The 
                    balance amount of money paid by the allottee shall be returned by TUDA to the allottee within 45 days of such cancellation
                    without any interest. </li> */}
                    <li > TUDA on receipt of ‘Total Amount Payable’ from the Allottee(s) specified
                      under the Agreement for Sale, shall execute a conveyance / sale deed and convey the title of the Flat together with 
                      proportionate indivisible share in the common areas and proportionate undivided share of land in favor of Allottee. </li>
<li ><b><i>The interior design including colours, textures, tiles design or any other representations etc., 
                      mentioned in the brochure and website are for the representation purpose only. The Applicant shall fully familiarize
                      himself/herself with the specifications of the Project in general and the selected flat in particular. Change of
                        Specifications will be at the sole discretion of TUDA </i></b> </li> 
                        
                        
<li ><b> <i>The furniture/furnishings shown 
                        in 3D Walkthrough / model flat / brochure / website are for representational purpose only, but not part of the sale 
                        or sale consideration or specifications of the said flat, and the same is for enabling the Applicant to estimate and 
                        gauge the space available for placing any such items in the flat. The actual specifications are detailed in the
                        Agreement for Sale to be executed subsequently.</i> </b></li>
<li > TUDA has no control on all the developments
                          that may exist or take place in future whatsoever, in the adjoining properties of the Project. </li>
                          <li > The location of the covered car park(s) will be marked after the completion of
                          the Project and the allotment of the specific slot for car parking shall be intimated by TUDA during
                            the execution of conveyance deed, on a Random generated Lottery. </li><li > 
                            In case of joint applicants, both the applicants are jointly and severally responsible for making all the payments
                            due within the timelines. All correspondence shall be sent to the primary applicant’s address for 
                            communication and will be applicable to the joint allottee(s) also. </li><li > 
                            Allottee(s) confirm that he/she/they have understood the scheme of development and are aware of 
                            the surrounding area and the neighbourhood of the project site. </li><li > 
                            Allottee(s) is / are eligible for executing the Conveyance Deed (sale deed) only after fulfilling
                              all the payment milestones provided in Agreement for Sale. </li><li >
                                In the event of any dispute, difference, question arising out of or in respect of this project or in
                                  any manner, whatsoever in connection with it, shall be subject to the local civil courts at Tirupati. 
                                  </li></ol></div></div></div>
                                 

        </details>
        </div>

      <div>
        <details>
          <summary><h5 style={{marginTop: "-24px",
  paddingLeft: "20px"}}> TERMS OF BOOKING PROCESS</h5></summary> 
        <p>1. Provisional Allotment of flat is strictly based on realisation of booking advance by TUDA on a online auctions.</p> 
          <p>2. Payment of booking advance towards booking a flat in TUDA  can be made only through online process on <a  href="https://www.tudaap.in/" target="blank">https://www.tudaap.in</a> &nbsp; (or) &nbsp;<a 
       href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a> website through online Payments.</p>
            
          <p>3. Applicant confirms, that he/ she has read the entire project information on the website including updates made from time to
             time, made his / her own analysis and checked accuracy, adequacy, correctness, reliability and completeness of the information
              provided in the website before proceeding for payment of booking advance.</p>
          <p>4.  For facilitating online payments, payment gateway service is made available through third party gateway, namely
             “CCAvenue”. The applicant understands and agrees to the terms of use of the Payment Gateway – CCAvenue. While best efforts
              are made by CCAvenue to extend optimum performance, TUDA shall not be responsible for issues related to CCAvenue performance,
               transaction failures etc.</p> 
          <p>5.  Booking advance from NRI's shall be accepted in Indian Rupees only. NRI's can make the payment only through their 
            Non-Resident Ordinary (NRO) account. </p>
        <div> <p>6.  Flat will be provisionally allocated only in case if the ‘transaction’ - whole lifecycle of flat booking process is
           completed 
          (selecting the flat till “Success” return message from CC Avenue striking TUDA  website) within stipulated
          time and complying with online auctions.
          <p>A transaction comprises of the following steps in two seamless sessions after
            the applicant agrees to the terms and conditions and fills his /her details</p>
          <li>a) “Session for flat selection” and</li>
          <li>b) “Session for successful payment”</li>
          These steps are to be conducted by the applicant/payment gateway/bank for successfully completing the booking process for a flat.
          </p>
          <p>a) Session for flat selection- for which session time out time is 5 minutes from its initiation:
          <li>  i) Selection of flat of applicant’s choice</li>
          <li> ii) Selecting clicking the payment button </li></p>
        <p>b) Session for successful Payment- for which session timeout time is 15 minutes from its initiation:
        <li>iii) Selection of mode of payment i.e., net banking /credit card/debit card etc., on the payment gateway</li>
        <li> iv) deduction of booking advance money by the applicant’s bank for the purpose of transferring the same to TUDA 
           through payment gateway</li>
        <li>v) Receipt of confirmation by the TUDA 
          website of the successful deduction of funds by the bank, through the payment gateway.</li>
</p>
          <p>The steps enlisted above – 1 to 5 covered under both “Session for flat selection “ and “Session 
            for successful payment” are collectively defined
            as “Transaction”. Any breakage/aborting/termination anywhere during the above steps
              in the “Transaction”, for whatsoever reason before it is concluded, will be treated as incomplete
              and unsuccessful transaction for the purpose of provisional allotment of flat. A transaction initiated
                will not be treated as successful until and unless it is concluded within respective session time-out
                time and even if the money is deducted by the bank of the applicant for such incomplete transaction, 
                the same will get auto reversed by the payment gateway and the applicant shall not have any claims 
                whatsoever for provisional allotment.</p>
          </div>
          
          <p>7.  In case of any incomplete transaction(s) (done through domestic bank accounts & NRO accounts),
            the refund shall be automatically done by the respective payment gateway. TUDA does not hold any
              liability of the amount deducted for such incomplete transaction as well as transaction charges (if any).</p>
          <p>8.  In case of any multiple/conflicting transactions on same flat for whatsoever reasons, the provisional
            allotment will be made to the applicant, whose transaction is concluded duly complying with the highest bidder.</p>
          <p>9.  In case, where the booking advance is made and the applicant has not received any payment acknowledgement 
            from TUDA , due to any reason, whatsoever, the entire booking advance shall be refunded within 15 days from 
            such payment.</p>
            <p>10.Tuda results are the right either to continue with the scheme (or) cancel the action without any intimation for
               the reason beyond their control the advance bookings will be refunded. 
              </p>

          <p>11. Failure of KYC process completion and execution of the Agreement for Sale within prescribed timeline
            may result in cancellation of the provisional allotment. The TUDA reserves the right to make available
              the flat for rebooking at a later date.</p>
          <p>12. If the provisional allottee decides to cancel the booking within forty five (45) days from the date
            of booking of the flat, fifty percentage (50%) of the booking advance shall be forfeited and the remaining
              shall be reimbursed by TUDA after deducting applicable taxes / charges (if any) within forty-five
              (45) days of such date of cancelation, without any interest.</p>
          <p>13. It is the sole responsibility of the applicant to provide correct information during the booking process.</p>
          <p>14. the information furnished is found incorrect, TUDA reserves the right to cancel the transaction / allotment.</p>
          <p>15. The TUDA reserves the right to c
          ancel the provisional allotment and exercise legal
            recourse for fradulant transactions.</p> 
              
            </details>       </div>     
<div>
<section id="payment-terms">
<details>
<summary> <h5 style={{marginTop: "-24px",
  paddingLeft: "20px"}}>PAYMENT TERMS</h5></summary>
<div  className="col-md-6">
<table  className="bform-table tc-table answer no-whitespace" style={{border:"10px"}}>
<tbody >
<tr >
<td >
<b >Base price</b></td>
<td > 2BHK - INR 5800 per sqft <br/>
  3BHK,4BHK -INR 5900 per sqft
  <br/>
  Commercial Ground  floor: INR 12000 per sqft,

Commercial first floor: INR 10000 per sqft,<br/>

Office : INR 7500 Per sqft
</td></tr>
<tr >
<td >
<b >Car Parking <span  className="clr-yellow">*</span></b></td>
<td > INR 200,000 per flat per car park / INR 350000 for 2 carparks Stacked parking<span  className="clr-yellow">*</span></td></tr>

<tr >
<td >
<b >East Facing Preferred Location Charges</b></td>
<td > INR 50 per sqft </td></tr>
<tr >
<td >
<b >Floor Rise Preferred Location Charges</b></td>
<td > INR 20 per sqft for floor rise shall be applicable from 6th level to 10th level AND
INR 40 per sqft shall be applicable from 10th to 14th level onwards </td></tr>
<tr >
<td >
<b >Infrastructure Development Cost</b></td>
<td > INR 100 per sqft for external Infrastructure cost </td></tr>
<tr >
<td >
<b >Corpus Fund</b></td>
<td > INR 50 per sqft (to be payable at the time of execution of conveyance / sale
deed). This amount will be deposited with Resident Welfare Association, once
it is constituted.. </td></tr>
<tr >
<td >
<b >Maintenance Charges</b></td>
<td > INR 2 per sqft per month for 12 months (to be payable at the time of execution
of conveyance / sale deed) </td></tr>
<tr >
<td  colspan="2"> 5% GST is applicable on base price, car parking cost, PLCs (Preferred Location Charges), and infrastructure
development costs. However, based on the reconciliation of accounts at the time of registration, actual GST
amount payable shall be arrived at and the difference in the GST amount, if any, shall be adjusted in the last
payment. </td></tr>
<tr >
<td  colspan="2"> 18% GST is applicable on maintenance charge collected for 12 months @2 ₹ per sqft per month on saleable
area </td></tr>
<tr >
  <td  colspan="2"> Registration Fee &amp; Stamp Duty shall be borne by the Allottee and payable to the registration department at the
time of registration of Agreement for Sale &amp; Conveyance / Sale Deed. </td></tr>
  <tr >
<td  colspan="2">
<span  className="clr-yellow">*</span>2 BHK Flats shall have one car parking at  ₹.2,00,000and 3 BHK and 4 BHK flats shall have two numbers
of mandatory car parking. In this case the allotee shall pay INR 350,000/- towards car parking cost for two car
parks. </td></tr>
<tr >
  <td  colspan="2"> GST is as per prevailing rates and may vary as per government decision from time to time. </td></tr>
  <tr >
  <td  colspan="2"> All infrastructure Connection charges payable to institutions like Power , Water, UDS , Gas, DTH,
Wifi etc are payable by the allottee </td></tr>
  
  </tbody></table></div>
  </details>
  </section>
  </div>
  
<div>
  <details>
  <summary>

<h5 style={{marginTop: "-24px",
  paddingLeft: "20px"}}>TECHNICAL TERMS & CONDITIONS </h5></summary>
<div>
<p>1. tudatowers.in <a  href="https://www.tudaap.in/" target="blank">https://www.tudaap.in</a> &nbsp; (or) &nbsp;<a 
       href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a> is the only URL/website/domain
through which applicant shall apply for booking in TUDA Towers project. TUDA can not be
held responsible for transactions on any other website(s) regarding TUDA ..</p>


<p>2. tudatowers.in <a  href="https://www.tudaap.in/" target="blank">https://www.tudaap.in</a> &nbsp; (or) &nbsp;<a 
       href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a> is best experienced on “Google
Chrome” browser. It’s performance may vary based on technical issues at applicant’s end
like “internet connectivity”, “hardware/software, browser” etc.</p> 

<p>3. By initiating the process of flat booking on the  tudaap.in/ tudatowers.in booking tuda platform, the applicant 
  agrees to all the terms and conditions and confirms
that his/her process of booking does not automatically result in to flat allotment.</p> 

<p>4. On this TUDA website, the following are treated as awareness issues from
applicant end like:
<li>a) “refreshing the url”</li>
<li>b) “closing/terminating/aborting the url/browser/tab”</li>
<li>c) “clicking on back button on browser”</li>
<li>d) “opening multiple sessions in same browser” etc.</li>
The list shown above is not exclusive and session would be terminated automatically
without any retention/warning in case if any of such action(s) mentioned above are
performed from applicant side.</p> 

<p>5. Flat will be provisionally allocated only in case if the ‘transaction’ - whole lifecycle of flat
booking process is completed (selecting the flat till “Success” return message from CC
Avenue striking TUDA website) within stipulated time and complying with “online actions”.</p> 

<p>6. A transaction comprises of the following steps in two seamless sessions after the
applicant agrees to the terms and conditions and fills his/her details;
<li>a) “Session for flat selection” and;</li>
<li>b) “Session for successful payment”</li>
These steps to be conducted by the applicant/payment gateway/bank for successfully 
completing the booking process for a flat.
a) Session for flat selection- for which session time out time is 5 minutes from its initiation:
<li>i) selection of flat of applicant’s choice</li>
<li>ii) selecting clicking the payment button</li>

b) Session for successful Payment- for which session timeout time is 15 minutes from its initiation:
<li>iii) selection of mode of payment i.e., net banking /credit card/debit card etc., on the payment gateway</li>
<li>iv) deduction of booking advance money by the applicant’s bank for the purpose of
transferring the same to TUDA through payment gateway</li>
<li>iv) Receipt of confirmation by the TUDA  website of the successful deduction of
funds by the bank, through the payment gateway.</li></p> 
<p>The steps enlisted above – 1 to 5 covered under both “Session for flat selection “ and
“Session for successful payment” are collectively defined as “Transaction”. Any
breakage/aborting/termination anywhere during the above steps in the “Transaction”, for
whatsoever reason before it is concluded, will be treated as incomplete and unsuccessful
transaction for the purpose of provisional allotment of flat. A transaction initiated will not
be treated as successful until and unless it is concluded within respective session time-
out time and even if the money is deducted by the bank of the applicant for such
incomplete transaction, the same will get auto reversed by the payment gateway and the
applicant shall not have any claims whatsoever for provisional allotment.</p> 

<p>7. It is expected that large number of applicants may try for booking of the flats immediately
after launch, and as such more than one applicant may be clicking on the same flat available
at that moment. However, the application will block the flat for the applicant whose response
is fastest. The speed of the applicant’s response may vary on the internet speed, hardware
and browser. The applicant shall not have any claims whatsoever against the application
and the TUDA in this regard.</p> 

<p>8. If in case, there are any unavoidable/unforeseen technical issues with respect to TUDA 
application at the time of online flat booking, TUDA reserves the right to exercise any or all of
such measures, including – to postpone the activity or to take appropriate
downtime/measures at any point of time to restore the services. The applicant agrees to any
circumstances arising out of such measures, which may include - applicant dropping off from
the session, payment gateway session termination, revoking the flat allocation confirmation
to the user through website/sms/email, payment processing and non- allocation of flat etc.
The applicant will not have any claim whatsoever against the TUDA and the TUDA
for non allotment of flat for such technical reasons.</p> 

<p>9. In case if the applicant enters incorrect name/details while filling the personal details form
(spelling mistakes, typo errors, manual errors etc.) and submits a successful transaction for
flat, Aadhaar number/Passport number will be taken as the key field for verification during
KYC process and the same details as presented during KYC process will be considered for
Agreement.</p> 

<p>10. All updates on booking, provisional allotment of flat, KYC process, agreement to sale and
all post allocation confirmation are regularly made availabe on the www.tudatowers.in <a  href="https://www.tudaap.in/" target="blank">https://www.tudaap.in</a> &nbsp; (or) &nbsp;<a 
       href="https://www.tudatowers.in/" target="blank">https://www.tudatowers.in</a> website.</p> 

<p>11. Force major conditions shall apply to the booking process.</p>   
</div>  </details>
<div>
  
 
<a routerlink="/option" href="/option" ><h5 style={{color:"white",
  paddingLeft: "20px"}}>AUCTION CONDITIONS</h5></a>
</div>
</div>
</div>


</div>

<Footer/>
</div>
  )
}
export default Conditions;