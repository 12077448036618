// import React from 'react';
// import './App.css';
// const Documents = () => {
//   const openPDF = () => {
//     window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf', '_blank');
//     window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf', '_blank');
//      window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf', '_blank');
//   };

//   return (
//     <div style={{backgroundColor:"rgb(241, 234, 234)",margin:"2px",height:"500px"}}>
        
//     <div style={{marginLeft:"40%",backgroundColor:"rgb(241, 234, 234)"}}>
//       <h1 >DOCUMENTS </h1><br />
//      <div style={{marginLeft:"-28%",backgroundColor:"white",marginRight:"37.5%",padding:"10px"}}> <h4 >AIRPORT CLEARANCE<br/> APPROVAL</h4><br/>
//       <img src="images/pdf-icon.png" style={{width:"100px",height:"100px",marginLeft:"8%"}}></img><br /><br/>
//       <button className="buttons1" onClick={openPDF} style={{width:"120px",marginLeft:"8%"}}>AIRPORT APPROVAL</button><br/><br /></div>


// <div >
//       <div style={{marginLeft:"35%",marginTop:"-38%"}}>  <h4>FIRE CLEARANCE<br/> APPROVAL</h4><br/>
//       <img src="images/pdf-icon.png" style={{width:"100px",height:"100px",marginLeft:"10%"}}></img><br /><br/>
//       <button className="buttons1" onClick={openPDF} style={{width:"120px",marginLeft:"10%"}}>FIRE<br/> APPROVAL</button><br/><br /></div>

          
//       </div>
      
    
//      </div>
//     </div>
//   );
// };

// export default Documents;


// import React from 'react';
// import './App.css';

// const Documents = () => {
//     const openPDF = () => {
//     window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf', '_blank');
//     window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf', '_blank');
//      window.open('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf', '_blank');
//   };

//   return (
//     <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
//       <div style={{  backgroundColor: 'rgb(241, 234, 234)' }}>
//         <h1 className='text-center'>DOCUMENTS</h1>
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//           {/* Airport Clearance Approval */}
//           <div style={{ backgroundColor: 'white', padding: '10px', marginRight: '20px' }}>
//             <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')} style={{ width: '120px', marginLeft: '8%' }}>AIRPORT APPROVAL</button><br /><br />
//           </div>

//           {/* Fire Clearance Approval */}
//           <div style={{ backgroundColor: 'white', paddingLeft: '30px',paddingTop:'20px',paddingRight:'30px', marginRight: '20px' }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')} style={{ width: '120px', marginLeft: '10%' }}>FIRE APPROVAL</button><br /><br />
//           </div>

//           {/* New Document Registration */}
//           <div style={{ backgroundColor: 'white', padding: '10px' }}>
//             <h4>TUDA REGISTRATION<br/> CERTIFICATE</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')} style={{ width: '120px', marginLeft: '8%' }}>REGISTRATION CERTIFICATE</button><br /><br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Documents;



// import React from 'react';
// import './App.css';

// const Documents = () => {
//   const openPDF = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
//     <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
//       <div style={{ backgroundColor: 'rgb(241, 234, 234)' }}>
//         <h1 className='text-center'>DOCUMENTS</h1>
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        
//           <div style={{ backgroundColor: 'white', padding: '10px', marginRight: '20px',textAlign:"center" }}>
//             <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')} style={{ width: '120px', marginLeft: '8%' }}>AIRPORT APPROVAL</button><br /><br />
//           </div>

          
//           <div style={{ backgroundColor: 'white', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', marginRight: '20px',textAlign:"center" }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')} style={{ width: '120px', marginLeft: '10%' }}>FIRE APPROVAL</button><br /><br />
//           </div>

         
//           <div style={{ backgroundColor: 'white', padding: '10px',textAlign:"center" }}>
//             <h4> RERA TUDA <br/>REGISTRATION CERTIFICATE</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')} style={{ width: '120px', marginLeft: '8%' }}>REGISTRATION CERTIFICATE</button><br /><br />
//           </div>
          

//           <div style={{ backgroundColor: 'white', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', marginRight: '20px',textAlign:"center" }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')} style={{ width: '120px', marginLeft: '10%' }}>FIRE APPROVAL</button><br /><br />
//           </div>
          
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Documents;

// import React from 'react';
// import './App.css';

// const Documents = () => {
//   const openPDF = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
//     <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
//       <div style={{ backgroundColor: 'rgb(241, 234, 234)' }}>
//         <h1 className='text-center'>DOCUMENTS</h1>
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//           {/* Document 1 */}
//           <div style={{ backgroundColor: 'white', padding: '10px', marginRight: '20px', textAlign: "center" }}>
//             <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')} style={{ width: '120px', marginLeft: '8%' }}>AIRPORT APPROVAL</button><br /><br />
//           </div>

//           {/* Document 2 */}
//           <div style={{ backgroundColor: 'white', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', marginRight: '20px', textAlign: "center" }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')} style={{ width: '120px', marginLeft: '10%' }}>FIRE APPROVAL</button><br /><br />
//           </div>

//           {/* Document 3 */}
//                   <div style={{ backgroundColor: 'white', padding: '10px',textAlign:"center" }}>
//              <h4> RERA TUDA <br/>REGISTRATION CERTIFICATE</h4>
//              <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')} style={{ width: '120px', marginLeft: '8%' }}>REGISTRATION CERTIFICATE</button><br /><br />
//            </div>

//           {/* Document 4 */}
//           <div style={{ backgroundColor: 'white', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', textAlign: "center" }}>
//             <h4>GOVT LOA for contractor</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/GOVT+LOA+for+contractor.pdf')} style={{ width: '120px', marginLeft: '10%' }}>OPEN DOCUMENT 4</button><br /><br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Documents;




// import React from 'react';
// import './App.css';

// const Documents = () => {
//   const openPDF = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
//     <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
//       <div style={{ backgroundColor: 'rgb(241, 234, 234)' }}>
//         <h1 className='text-center'>DOCUMENTS</h1>

//         {/* First Row: Document 1 and Document 2 */}
//         <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
//           {/* Document 1 */}
//           <div style={{ backgroundColor: 'white', paddingTop: '10px',paddingLeft:"60px",paddingBottom:"20px",paddingRight:"30px", marginRight: '20px', textAlign: "center" }}>
//             <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')} style={{ width: '120px', marginLeft: '8%' }}>AIRPORT APPROVAL</button><br /><br />
//           </div>

//           {/* Document 2 */}
//           <div style={{ backgroundColor: 'white', paddingLeft: '70px', paddingTop: '20px', paddingRight: '60px', marginRight: '20px', textAlign: "center" }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')} style={{ width: '120px', marginLeft: '10%' }}>FIRE APPROVAL</button><br /><br />
//           </div>
//         </div>

//         {/* Second Row: Document 3 and Document 4 */}
//         <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '20px' }}>
//           {/* Document 3 */}
//           <div style={{ backgroundColor: 'white', padding: '10px', textAlign: "center" }}>
//             <h4> RERA TUDA <br/>REGISTRATION CERTIFICATE</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '8%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')} style={{ width: '120px', marginLeft: '8%' }}>REGISTRATION CERTIFICATE</button><br /><br />
//           </div>

//           {/* Document 4 */}
//           <div style={{ backgroundColor: 'white', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', textAlign: "center" }}>
//             <h4>GOVT LOA for Contractor</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px', marginLeft: '10%' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/GOVT+LOA+for+contractor.pdf')} style={{ width: '120px', marginLeft: '10%' }}>GOVT LOA for Contractor</button><br /><br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Documents;



// import React from 'react';
// import './App.css';

// const Documents = () => {
//   const openPDF = (url) => {
//     window.open(url, '_blank');
//   };

//   return (
//     <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
//       <div style={{ backgroundColor: 'rgb(241, 234, 234)' }}>
//         <h1 className='text-center'>DOCUMENTS</h1>

//         {/* First Row: Document 1 to Document 4 */}
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
//           {/* Document 1 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')}>AIRPORT APPROVAL</button><br /><br />
//           </div>

//           {/* Document 2 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')}>FIRE APPROVAL</button><br /><br />
//           </div>

//           {/* Document 3 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>RERA TUDA REGISTRATION CERTIFICATE</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')}>REGISTRATION CERTIFICATE</button><br /><br />
//           </div>

//           {/* Document 4 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>GOVT LOA for Contractor</h4>
//             <img src="images/pdf-icon.png" style={{ width: '100px', height: '100px' }} alt="PDF" /><br /><br />
//             <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/GOVT+LOA+for+contractor.pdf')}>GOVT LOA for Contractor</button><br /><br />
//           </div>
//         </div>

//         {/* Second Row: Document 5 to Document 8 */}
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', padding: '10px' }}>
//           {/* Document 5 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>Document 5</h4>
//             {/* Add image and button for Document 5 */}
//           </div>

//           {/* Document 6 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>Document 6</h4>
//             {/* Add image and button for Document 6 */}
//           </div>

//           {/* Document 7 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>Document 7</h4>
//             {/* Add image and button for Document 7 */}
//           </div>

//           {/* Document 8 */}
//           <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center" }}>
//             <h4>Document 8</h4>
//             {/* Add image and button for Document 8 */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Documents;




import React from 'react';
import './App.css';

const Documents = () => {
  const openPDF = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div style={{ backgroundColor: 'rgb(241, 234, 234)', margin: '2px', height: '500px' }}>
      <div style={{ backgroundColor: 'rgb(241, 234, 234)' }}>
        <h1 className='text-center'>DOCUMENTS</h1>

        {/* First Row: Document 1 to Document 4 */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          {/* Document 1 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>AIRPORT CLEARANCE<br /> APPROVAL</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS+airport+clearance.pdf')}>AIRPORT APPROVAL</button>
          </div>

          {/* Document 2 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>FIRE CLEARANCE<br /> APPROVAL</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA+TOWERS-FIRE+approval.pdf')}>FIRE APPROVAL</button>
          </div>

          {/* Document 3 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>RERA TUDA REGISTRATION CERTIFICATE</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/TUDA_TOWERS_RERA_APPROVAL.pdf')}>REGISTRATION CERTIFICATE</button>
          </div>

          {/* Document 4 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>GOVT LOA FOR <br/>CERTIFICATE</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/GOVT+LOA+for+contractor.pdf')}>GOVT LOA for Contractor</button>
          </div>
        </div>



         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          {/* Document 1 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>246 COLLECTOR PROCEEDINGS</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/246+collector+proceedings.pdf')}>246 collector proceedings</button>
          </div>

          {/* Document 2 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>246 GO<br/>CERTIFICATE</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/246+GO.pdf')}>246 GO Certificate</button>
          </div>

          {/* Document 3 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>246 HANDING OVER<br/> CERTIFICATE</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/246+handing+over+(1).pdf')}>246 Handing Over</button>
          </div>

          {/* Document 4 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>GOVT ADMIN <br/>SANCTION</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/Govt+admin+sanction.pdf')}>Govt admin Sanction</button>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          {/* Document 1 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>Building Permit Order</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/tuda+towers+plng+proceedings.pdf')}>Building Permit Order</button>
          </div>

          {/* Document 2 */}
          <div style={{ backgroundColor: 'white', flex: '1', padding: '10px', textAlign: "center", borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
            <h4>Tuda Towers Approved Plans</h4>
            <img src="images/pdf-icon.png" style={{ width: '70px', height: '70px' }} alt="PDF" /><br /><br />
            <button className="buttons1" onClick={() => openPDF('https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/documents/tuda+towers+appd+plans+(2).pdf')}>Tuda Towers Approved Plans</button>
          </div>

          {/* Document 3 */}
         

          {/* Document 4 */}
         
        </div>
      </div>
    </div>
  );
};

export default Documents;
