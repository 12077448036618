import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Contact.css';
import Footer from './Footer';

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  return (
    
<div>
<img  alt="" height="500" 
    src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/contact.png" title="" width="100%" style={{marginLeft:"0%",marginRight:"0%",backgroundColor:"rgb(241, 234, 234)"}}></img>
      

    <section className="page-section pb100" style={{backgroundColor:"rgb(241, 234, 234)"}}>
    <div className="line-container project-brief123 fw300 black-text" style={{padding:"10px"}}>
      <div className="row">
        <div className="col-md-12">
          <div className="title-gap">
            <h1 className="chead">
              <img src="Images/circle-head.png" alt="" 
               style={{width:"60px",marginLeft:"10%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}/>

              <span className="black long" style={{fontSize:"21px",marginleft:"13%"}}>GATED COMMUNITY FLATS FOR SALE IN TIRUPATI - ANDHRA PRADESH</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="row pt50" style={{padding:"1% 4% 1% 10%"}}>
        <div className="col-md-6 line-pad spec-info" >
          <h5 style={{marginLeft:"2%"}}>GET IN TOUCH</h5>
          <div className="pt30" >
            <div className="form-group"  style={{padding:"5px"}}>
              <input
                className="form-control cpholder ng-untouched ng-pristine ng-invalid"
                id="FirstName"
                name="name"
                placeholder="Name"
                required
                type="text"style={{ width: '100%',height:"45px" }}
              />
            </div>
            <div className="form-group" style={{padding:"5px"}}>
              <input
                className="form-control cpholder ng-untouched ng-pristine ng-invalid"
                id="Email"
                name="email"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                placeholder="Email"
                required
                type="text"style={{ width: '100%',height:"45px" }}
              />
            </div>
            <div className="form-group" style={{padding:"5px"}}>
              <input
                className="form-control cpholder ng-untouched ng-pristine ng-invalid"
                id="phone"
                name="mobileNumber"
                placeholder="Phone"
                required
                type="text"
                onlynumber="true"style={{ width: '100%',height:"45px" }}
              />
            </div>
            <div className="form-group" style={{padding:"5px"}}>
              <textarea
                className="form-control lh20 cpholder ng-untouched ng-pristine ng-valid"
                id="message"
                name="message"
                placeholder="Message"
                rows="6"
                style={{ width: '100%',height:"90px" }}
              />
            </div>
            <div className="cont-submit-mln" style={{padding:"5px"}}>
              <button className="more-lh20" type="submit"  style={{marginLeft:"40%",width:"90px",backgroundColor:"blue"}}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2 line-pad pl30 mob-pln mob-pt50"  style={{   marginLeft: "7%"}}>
          <h5>CORPORATE ADDRESS</h5>
          <div>
            <p className="text-left pb20" >
              Tirupati Urban Development Authority (TUDA),
              <br />
              Tuda Office Road,
              <br />
              Tirupati -  517501.
              <br />
              Andhra Pradesh - INDIA.
            </p>
          </div>
          <br />
          <h5>SITE ADDRESS</h5>
          <div>
            <p className="text-left pb20">
              TUDA Towers,
              <br />
             Air bypass Road,
              <br />
              Near Annamaiah Circle, Tirupati green city,
              <br />
              Tirupati-517501,<br/> Andhra Pradesh.
            </p>
            <p></p>
          </div>
        </div>
        <div className="col-md-2 line-pad"  style={{   marginLeft: "8%"}}> 
          <h5 className="mb10" >CALL US</h5>
          <div>
            <p>+91 0877-222 5730</p>
          </div>
          <div className="pt20">
            <h5 className="mb10">EMAIL US</h5>
            <div>
              <p>
                <a href="https://www.tudaap.in">vctuda@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt50">
<div className="col-md-12">
  <div className="title-gap">
    <h3 className="chead">
      <img src="images/map-icon.jpg" alt="" style={{width:"3%",marginLeft:"10%",backgroundColor:"#e2dbdbfd"}}/>
      <span className="black">LOCATION MAP</span>
    </h3>
  </div>
</div>
</div>

      <div className="row" style={{ backgroundColor: '#e2dbdbfd' }}>
      <div className="col-md-12">
        <div style={{ border: '0', width: '80%', marginLeft: '10%', backgroundColor: '#e2dbdbfd' }}>
          <img
            onClick={handleImageClick}
            src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg"
            alt="TUDA Towers GMS"
            style={{ width: '100%', cursor: 'pointer' }}
          />
        </div>
      
      </div>
    </div>
    </div>
    <div className="line-one ltgray-one"></div>
    <div className="line-two ltgray-two"></div>
    <div className="line-three ltgray-three"></div>
    <div className="line-four ltgray-four"></div>
    <div className="line-five ltgray-five"></div>
  </section>
  {isOpen && (
          <Lightbox
            mainSrc="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg"
            onCloseRequest={handleClose}
          />
        )}
  <Footer/></div>
  
  
  );
};

export default Contact;