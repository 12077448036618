import React from 'react'

const Pricing = () => {
  return (
    <div style={{backgroundColor:"#f1eaea",margin:"3px"}}>
      {/* <div>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>FLAT PHASING</h3>
        <img src="images/plat phasing.jpg" style={{width:"1350px",height:"550px",padding:"30px"}} ></img>
      </div><br />
      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>COST OF FLAT</h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/costing/cost+of+plat.jpg" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>

      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>COST OF FLOORS</h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/costing/2+excel+img-01.png" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>

      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>COST OF COMMERICAL/OFFICE</h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/costing/2+excel+img-02.png" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>


      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>COST OF SHOPS</h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/costing/2+excel+img-03.png" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div> */}


      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>Pricing of Commercial Space  <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Commerical.pdf' target='__blank' style={{fontSize:"21px",fontWeight:"bold"}}>Commercial Schedule</a></h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/excel+sheet+1-04.jpg" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>
      
      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>Pricing of Residential Flats <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda_latest_Schedule_Residential.pdf' target='__blank' style={{fontSize:"21px",fontWeight:"bold"}}>Residential Schedule</a></h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/Excel+sheet+2-03.png" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>


      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>Pricing of Office Space  <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Office+Space.pdf' target='__blank' style={{fontSize:"21px",fontWeight:"bold"}}>Office Schedule</a></h3>
        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/excel+sheet+1-01.jpg" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>
<div>
        <h2>STAGES OF PAYMENTS</h2>
        </div>
      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>Stages Of Commercial/Office  Payments </h3>

        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/excel+sheet+1-03.jpg" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>

      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>Stages Of Residential Payments </h3>

        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/Excel+sheet+2-02.png" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>

      <div style={{backgroundColor:"#f1eaea"}}>
      <img src="/images/circle-head.png" style={{marginLeft:"2%",width:"4%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        <h3 style={{marginLeft:"6%",marginTop:"-3%"}}>TUDA Towers Overview </h3>

        <img src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/excel+sheet+1-02.jpg" style={{width:"1350px",height:"550px",padding:"30px"}}></img>
      </div>



    </div>

  )
}

export default Pricing;
