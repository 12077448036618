// App.js
import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About';
import MasterPlans from './MasterPlans';
import Amenities from './Amenities';
import Gallery from './Gallery';
import Contact from './Contact';
 import Header from './Header'
import Conditions from './Conditions';
// import Brochure from './Brochure';
import LoginForm from './LoginForm';
import 'bootstrap/dist/css/bootstrap.min.css'
import Visuals from './Visuals';
import Documents from './Documents';
import Explore from './Explore';
import Option from './Option';
import Pricing from'./Pricing';
import Intrest from './Intrest';
import axios from 'axios';

const App = () => {
  const [obj, setObj] = useState({
    city: "",
    ip: "",
    region: "",
    country: "",
    postalcode: "",
  });

  useEffect(()=>{
    axios.get('https://api.tudatowers.in/tudatowerscounter').then(()=>console.log('count')).catch((err)=>console.log(err))
    console.log('check count');
  },[0])


  useEffect(() => {
    axios
      .get(
        "https://ipgeolocation.abstractapi.com/v1/?api_key=609e7f781e1a483f84f48e75394d8efc"
      )
      .then((response) => {
        console.log(response);
        setObj(response.data);

        // setTimeout(() => {
        if (response.status === 200) {
          console.log(obj, "object");
          axios
            .post("https://api.tudatowers.in/savelocation", response.data)
            .then((response) => {
              console.log(response, "successresponse");
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // }, 4000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Router>
       <Header />
      <Routes>
      <Route  path="/conditions" element={<Conditions/>} />
        {/* <Route path="/brochure" element={<Brochure/>} /> */}
        <Route path="/loginform" element={<LoginForm/>} />
        <Route exact path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/masterplans" element={<MasterPlans/>} />
        <Route path="/amenities" element={<Amenities/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/visuals" element={<Visuals/>} />
        <Route path="/documents" element={<Documents/>} />
        <Route path="/explore" element={<Explore/>} />
        <Route path="/option" element={<Option/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/intrest" element={<Intrest/>} />
      </Routes>
    </Router>
  );
};

export default App;

