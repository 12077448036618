import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
 import './MasterPlans.css';

const images = [
  [
    //0
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/left+view+entry+26.jpg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+entry+27.jpg",
    // "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/tuda+aerial+face+hill+200722.jpg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/TUDA+FRONT+VIEW+X+270722.jpg"
  ],
  [
    //1
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/Site+Plan.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/FIRST+FLOOR+PLAN.png",

    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/GROUND+FLOOR+PLAN.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/SECOND+FLOOR+PLAN.png",
    
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/TYP+PLAN.png"
  ],
  [
    //2
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/GROUND+FLOOR+PLAN.png"
   
  ],
  [  
    //3
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/FIRST+FLOOR+PLAN.png"
   
  ],
  [
    //4
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/SECOND+FLOOR+PLAN.png",

  ],
  [
  //5
  // "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/8+2+BHK+East+Facing+1.png",
  //  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/9+2+BHK+East+Facing+2.png",
  // "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/10+2+BHK+West+Facing+1.png",
  // "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png",
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Ground+Floor+Plan+(1).jpg"
],
  [
//2bhk easst
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/9+2+BHK+East+Facing+2.png",
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/9+2+BHK+East+Facing+2.png",
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/8+2+BHK+East+Facing+1.png",
   
//6
  ],
  [
//2bh west

// "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png",
//   "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png"
//7
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/2+BHK+EAST+FACINGNew.jpg",
  ],
  [
//3bhk
//8
      "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png",
  ],
  [
    //9
    //3bhk east
    // "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/14+3BHK+East+Facing+1+F.png",
    //   "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/15+3BHK+East+Facing+2+F.png",
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/2+BHK+WEST+FACINGNew.jpg",
  ],
  [
    //10
//3bhk west
"https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/15+3BHK+East+Facing+2+F.png"
  ],
  [
    //11
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/3+BHK+EAST+FACINGNew.jpg"
],
[
    //12
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/13+3+BHK+West+Facing+2F.png",
    
],
[
  //13
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/3+BHK+WEST+FACINGNew.jpg"
],
[
  //14
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/4bhk/16+4BHK+F.png"
],
[
  //15
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/4bhk/4+BHK+NORTH+FACINGNew.jpg"
],
[
  //16
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/First+Floor+Plan.jpg"
],
[
  //17
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Second+Floor+Plan.jpg"
],
[
  //18
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Third+Floor+Plan.jpg"
],
[
  //19
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Fourth+Floor+Plan.jpg"
],
[
  //20
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Fifth+Floor+Plan.jpg"
],
[
  //21
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Sixth+Floor+Plan.jpg"
],
[
  //22
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Seventh+Floor+Plan.jpg"
],
[
  //23
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Eighth+Floor+Plan.jpg"
],
[
  //24
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Nineth+Floor+Plan.jpg"
],
[
  //25
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Tenth+Floor+Plan.jpg"
],
[
  //26
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Eleventh+Floor+Plan.jpg"
],
[
  //27
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Twelfth+Floor+Plan.jpg"
],
[
  //28
  "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Thirteenth+Floor+Plan.jpg"
],
];

class MasterPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryIndex: 0,
      activeIndex: 0,
      photoIndex: 0,
      isOpen: false
    };
  }


  handleImageClick = (categoryIndex, photoIndex) => {
    this.setState({
      categoryIndex,
      photoIndex,
      isOpen: true
    });
  };

  render() {
    const { categoryIndex, photoIndex, isOpen } = this.state;
    const categoryImages = images[categoryIndex];
    const { activeIndex } = this.state;

    return (
     
      <div className="row">
      <div className="col-12">
     
        <div className="carousel-inner" style={{ width: "90%", marginLeft: "5.5%", backgroundColor: "rgb(241, 234, 234)" }}>
          <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
            <img className="d-block w-100" src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/Gallery.png" alt="" height="550" />
          </div>
          <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
            <img className="d-block w-100" src="images/TUDA VIEW 4 FRONT.jpg" alt="" height="550" />
          </div>
          <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
            <img className="d-block w-100" src="images/TUDA VIEW 3.jpg" alt="" height="550" />
          </div>
          
        </div>
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className={activeIndex === 0 ? 'active' : ''}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1" className={activeIndex === 1 ? 'active' : ''}></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2" className={activeIndex === 2 ? 'active' : ''}></li>
        </ol>
      </div>
    

              
        <div className="row pb-100 mob-pb-100">
          <div className="col-md-6" >
            <div className="title-gap mob-pln"
             style={{ paddingLeft: '50px' }}>
              <h1 className="chead ml-50">
                <img
                  src="images/circle-head.png"
                  alt=""
                  style={{ width: '8%',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                />
                <span className="black" style={{ fontSize: '21px' }}>
                  MASTER PLAN
                </span>
              </h1>
            </div>


            <div className="layout-ph10">
              <p className="lay123" style={{ position: 'unset', zIndex: '99' }}>
                <img
                  className="mob-mp-400"
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg"
                  style={{ width: '400px',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                  alt=""
                  
                />
              </p>
              <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-10%' }}>
                
  
                <a
    className="zoom-circle"
    
    rel="prettyPhoto[mp]"
    style={{ marginRight: '20px' }}
    onClick={() => this.handleImageClick(0,0)}
   
  >
    ZOOM
  </a>
              </div>
            </div>
          </div>
            {/* <div className="layout-ph10">
              <p className="lay123" style={{ position: 'unset', zIndex: '99' }}>
                <img
                  className="mob-mp-400"
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg"
                  alt=""
                  style={{ width: '400px' ,backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}
               
                />
              </p>
              <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-13%' }}>
              
  
                <a
    className="zoom-circle"
    
    rel="prettyPhoto[mp]"
    style={{ marginRight: '20px' }}
    onClick={() => this.handleImageClick(0,0)}
 
  >
    ZOOM
  </a>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 mob-pt-50">
            <div className="title-gap mob-pln" style={{ paddingLeft: '50px' }}>
              <h1 className="chead ml-50">
                <img
                  src="images/circle-head.png"
                  alt=""
                  style={{ width: '8%',marginLeft:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                />
                <span className="black" style={{ fontSize: '21px' }}>
                  SITE LEVEL MASTER PLAN
                </span>
              </h1>
            </div>
            <div className="layout-ph10">
              <p className="lay123" style={{ position: 'unset', zIndex: '99' }}>
                <img
                  className="mob-mp-400"
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/Site+Plan.png"
                  style={{ width: '400px',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                  alt=""
                  
                />
              </p>
              <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-10%' }}>
                
  
                <a
    className="zoom-circle"
    
    rel="prettyPhoto[mp]"
    style={{ marginRight: '20px' }}
    onClick={() => this.handleImageClick(1,0)}
   
  >
    ZOOM
  </a>
              </div>
            </div>
          </div>
    



{/* 
          <div className="row" >
          <div className="col-lg-6 line-pad aboutbrief pr50 text-justify mob-mb100">
            <div className="title-gap">
              <h1 className="chead">
                <img src="images/circle-head.png" alt="Circle Head"   
                style={{ width: '8%',marginLeft:"5%" ,backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}  />
                <span className="black" style={{ fontSize: '21px' }}> TYPICAL FLOOR PLANS </span>
              </h1>
            </div>
          </div>
        </div> */}

          <div className="row pb100"  >
        <div className="block-description block-ttl bgn" >
        <h1 className="chead">
                <img src="images/circle-head.png" alt="Circle Head"   
                style={{ width: '4%',marginLeft:"5%" ,backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}  />
                <span className="black" style={{ fontSize: '21px' }}> TYPICAL FLOOR PLANS </span>
              </h1>
        </div>

        {/* Groun Floor */}
        <div className="col-md-4 mob-mb100" >
          <div className="layout-ph10" style={{ paddingLeft: '20%' }} >
            <div className="block-description">
              <h5>Ground Floor</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/GROUND+FLOOR+PLAN.png"
                alt="Ground Floor"
                style={{ width: '300px',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-14%' }}>
          


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(2,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* First Floor */}
        <div className="col-md-4 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>First Floor</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/FIRST+FLOOR+PLAN.png"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(3,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* 2nd Floor to 18th Floor */}
        <div className="col-md-4 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>2nd Floor to 13th Floor</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src=    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/SECOND+FLOOR+PLAN.png"

                alt="2nd Floor to 14th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
            

              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(4,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>
      </div>
      </div>


     
      
      <div className="row pb100"  style={{ paddingLeft: '5%',padding:"50px" }}>
        <div className="block-description block-ttl bgn" >
        <h1 className="chead">
                <img src="images/circle-head.png" alt="Circle Head"   
                style={{ width: '4%',marginLeft:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}  />
                <span className="black" style={{ fontSize: '21px' }}> FLAT VIEWS </span>
              </h1>
        </div>
<div>
    <h5></h5>
</div>
       

        {/* First Floor */}
        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>2 BHK East Facing (3D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/9+2+BHK+East+Facing+2.png"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-12%' }}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(6,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>


        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>2 BHK East Facing (2D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/2+BHK+EAST+FACINGNew.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%', marginTop: '-30%' }}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(7,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* 2nd Floor to 18th Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>2 BHK West Facing (3D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
            

              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(8,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>2 BHK West Facing (2D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/2+BHK+WEST+FACINGNew.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%', marginTop: '-30%' }}>
            

              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(9,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>
      </div>

      


      <div className="row pb100"  style={{ paddingLeft: '5%',padding:"50px" }}>
        <div className="block-description block-ttl bgn"  >
          <h5></h5>
        </div>

        {/* Ground Floor */}
      

        {/* First Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10">
            <div className="block-description">
              <h5>3 BHK East Facing (3D) </h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/15+3BHK+East+Facing+2+F.png"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
             


              <a
  className="zoom-circle"
  
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(10,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10" style={{ paddingLeft: '20%' }} >
            <div className="block-description">
              <h5>3 BHK East Facing (2D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/3+BHK+EAST+FACINGNew.jpg"
                alt="Ground Floor"
                style={{ width: '300px',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '45%', marginTop: '-35%' }}>
          


              <a
  className="zoom-circle"
rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(11,0)}

>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* 2nd Floor to 18th Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>3 BHK West Facing (3D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/13+3+BHK+West+Facing+2F.png"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
            

              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(12,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>3 BHK West Facing (2D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/3+BHK+WEST+FACINGNew.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%', marginTop: '-30%' }}>
            

              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(13,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>
<div className="row pb100"  style={{padding:"100px"}} >
       

        {/* First Floor */}
        <div className="col-md-4 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>4 BHK North Facing (3D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/4bhk/16+4BHK+F.png"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '25%', marginTop: '-16%' }}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(14,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>4 BHK North Facing (2D)</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/4bhk/4+BHK+NORTH+FACINGNew.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '30%', marginTop: '-25%' }}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(15,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>
        </div>
      </div>


      <div className="row pb100"  style={{ paddingLeft: '5%',padding:"50px" }}>
        <div className="block-description block-ttl bgn" >
        <h1 className="chead">
                <img src="images/circle-head.png" alt="Circle Head"   
                style={{ width: '4%',marginLeft:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}  />
                <span className="black" style={{ fontSize: '21px' }}> FLOOR PLANS </span>
              </h1>
        </div>
<div>
    <h5></h5>
</div>
       



{/* ground floor */}

        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Ground Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99',paddingTop:"-50%" }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Ground+Floor+Plan+(1).jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(5,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>


        {/* First Floor */}
        <div className="col-md-3 mob-mb100" style={{padding:"10%"}}>
          <div className="layout-ph10">
            <div className="block-description">
              <h5>First Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99',paddingTop:"-50%" }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/First+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px"}}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(16,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>


        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Second Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Second+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{marginLeft: '40%',marginTop:"-70px" }}>
             


              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(17,0)}
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* 2nd Floor to 18th Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>Third Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Third+Floor+Plan.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
            

              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(18,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>

      </div>

      


      <div className="row pb100"  style={{padding:"20%"}}>
        <div className="block-description block-ttl bgn" style={{padding:"20px"}} >
          <h5></h5>
        </div>

        {/* Ground Floor */}

        
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>Fourth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Fourth+Floor+Plan.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px'}}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
            

              <a
  className="zoom-circle"
 
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(19,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>
      

        {/* First Floor */}
        <div className="col-md-3 mob-mb100" style={{padding:"20%"}}>
          <div className="layout-ph10" >
          <div className="layout" >
            <div className="block-description">
              <h5>Fifth Floor Plan</h5>
            </div>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99'}}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Fifth+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{marginLeft: '40%',marginTop:"-70px"}}>
             


              <a
  className="zoom-circle"
  
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(20,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10" style={{ paddingLeft: '20%' }} >
            <div className="block-description">
              <h5>Sixth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Sixth+Floor+Plan.jpg"
                alt="Ground Floor"
                style={{ width: '300px',backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply" }}
                
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '30%', marginTop: '-70%' }}>
          


              <a
  className="zoom-circle"
rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(21,0)}

>
  ZOOM
</a>
            </div>
          </div>
        </div> */}



        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>Sixth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Sixth+Floor+Plan.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
            

              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(21,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* 2nd Floor to 18th Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>Seventh Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Seventh+Floor+Plan.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
            

              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(22,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        
<div className="row pb100"   style={{ paddingLeft: '5%',padding:"50px" }}>

<div className="col-md-3 mob-mb100">
          <div className="layout-ph10" >
            <div className="block-description" >
              <h5>Eight Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99'  }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Eighth+Floor+Plan.jpg"
                alt="2nd Floor to 1th Floor"
                style={{ width: '300px' }}
             
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
            

              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }} onClick={() => this.handleImageClick(23,0)}
  
>
  ZOOM
</a>
            </div>
          </div>
        </div>
       

        {/* First Floor */}
        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Nineth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Nineth+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(24,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Tenth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Tenth+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px"}}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(25,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Eleventh Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99'}}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Eleventh+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(26,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

       
       
       
        </div>
      </div>

      <div className="row pb100"  style={{ paddingLeft: '5%',padding:"50px" }}>
        <div className="block-description block-ttl bgn"  >
          <h5></h5>
        </div>

        {/* Ground Floor */}
        <div className="col-md-3 mob-mb100" >
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Twelfth Floor Plan</h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99' }}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Twelfth+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px"}}>
             


              <a
  className="zoom-circle"

  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(27,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

        {/* First Floor */}
        <div className="col-md-3 mob-mb100">
          <div className="layout-ph10">
            <div className="block-description">
              <h5>Thirteenth Floor Plan </h5>
            </div>
            <p className="lay" style={{ position: 'unset', zIndex: '99'}}>
              <img
                src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/FourK/Thirteenth+Floor+Plan.jpg"
                alt="First Floor"
                style={{ width: '300px' }}
              
              />
            </p>
            <div className="zoom center" style={{ marginLeft: '40%',marginTop:"-70px" }}>
             


              <a
  className="zoom-circle"
  
  rel="prettyPhoto[mp]"
  style={{ marginRight: '20px' }}
  onClick={() => this.handleImageClick(28,0)}
 
>
  ZOOM
</a>
            </div>
          </div>
        </div>

       

      </div>




      {isOpen && (
          <Lightbox
            mainSrc={categoryImages[photoIndex]}
            nextSrc={categoryImages[(photoIndex + 1) % categoryImages.length]}
            prevSrc={categoryImages[(photoIndex + categoryImages.length - 1) % categoryImages.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + categoryImages.length - 1) % categoryImages.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % categoryImages.length
              })
            }
          />
        )}
      </div>
    );
  }
}

export default MasterPlans;
