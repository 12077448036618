
import React, { useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
 import './Navbar.css';

import { Navbar, NavDropdown, Form, FormControl, Button, Nav } from 'react-bootstrap'

const Header = () => {
 let location=useLocation()
  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (buttonName) => {
    setClickedButton(buttonName);
  };

  const handleButtonClick = () => {
    window.location.href = 'https://tudaproperty.auctiontiger.net/EPROC/';
  };
  const pathname = window.location.pathname 
  console.log(pathname,"pathname");

  return (
    <div style={{ backgroundColor: "#f1eaea"}}>
     
      <Navbar activeKey={location.pathname}  bg="grey" variant={"dark"} expand="lg">
            <Navbar.Brand href="#"> 
               <img
          alt="Site Logo"
          className=""
          src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/FINAL+New+logo+black.jpg"

          height={70}
          width={100}
          style={{
            
            backgroundColor: "#fbeec1",
            mixBlendMode: "multiply",
          }}
        ></img>
        </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="mr-auto my-2 my-lg-0"
                navbarScroll
                activeKey={location.pathname} 
                style={{whiteSpace:'nowrap'}}
               
              >
                <Nav.Link as={Link} to="/" className={(pathname == '/')?'navActive':''}  >
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/about" className={(pathname == '/about')?'navActive':''}>
                  About Project
                </Nav.Link>
                <Nav.Link as={Link} to="/masterplans" className={(pathname == '/masterplans')?'navActive':''}>
                  Master Plans & Floor plans
                </Nav.Link>

                <Nav.Link as={Link} to="/amenities" className={(pathname == '/amenities')?'navActive':''}>
                 Amenities and Specifications
                </Nav.Link>
                <Nav.Link as={Link} to="/gallery" className={(pathname == '/gallery')?'navActive':''}>
                Gallery
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" className={(pathname == '/contact')?'navActive':''}>
                  Contact Us
                </Nav.Link>
                <Nav.Link as={Link} to="/conditions" className={(pathname == '/conditions')?'navActive':''}>
                  Terms and Conditions
                </Nav.Link>

                <Nav.Link as={Link} to="/pricing" className={(pathname == '/pricing')?'navActive':''}>
                  Pricing
                </Nav.Link>

                <Nav.Link as={Link} to="/documents" className={(pathname == '/documents')?'navActive':''}>
                 Documents
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/gallery">
                0877-222 5730
                </Nav.Link> */}
                <Nav.Link as={Link} to="https://user.tudatowers.in/">
                  Customer Login
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
      {/* <div className="nav1">
        <nav>
          <ul className="button1">
            <li>
              <button
                className={`buttons1 ${clickedButton === "terms" ? "clicked" : ""}`}
                style={{ backgroundColor: "#40E0D0", color: "white", height: "150%" }}
                onClick={() => handleClick("terms")}
              >
                <Link to="/conditions">TERMS & CONDITIONS</Link>
              </button>
            </li>
            <li>
              <button
                className={`buttons1 ${clickedButton === "brochure" ? "clicked" : ""}`}
                style={{ backgroundColor: "#40E0D0", color: "white", height: "150%" }}
                onClick={() => handleClick("brochure")}
              >
                <Link to="/costing">COSTING</Link>
              </button>
            </li>
            <li>
              <button
                className={`buttons1 ${clickedButton === "explore" ? "clicked" : ""}`}
                style={{ backgroundColor: "#40E0D0", color: "white", height: "150%" }}
                onClick={() => handleClick("explore")}
              >
                <Link to="/documents">DOCUMENTS</Link>
              </button>
            </li>
            <li>
              <button
                className={`buttons1 ${clickedButton === "phone" ? "clicked" : ""}`}
                style={{ backgroundColor: "#40E0D0", color: "black", height: "150%" }}
                onClick={() => handleClick("phone")}
              >
                <b>0877-222 5730</b>
              </button>
            </li>
            <li>
              <button
                className={`buttons1 ${clickedButton === "login" ? "clicked" : ""}`}
                style={{ backgroundColor: "#40E0D0", color: "Black", height: "150%" }}
                onClick={handleButtonClick} ><b>CUSTOMER LOGIN</b>
              
           
              </button>
   

            </li>
          </ul>
        </nav>
      </div>

      <nav className="navbar" style={{ marginLeft: "32%"}}>
        <ul>
          <li>
            <button
              className={`buttons ${clickedButton === "home" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("home")}
            >
              <Link to="/">HOME</Link>
            </button>
          </li>
          <li>
            <button
              className={`buttons ${clickedButton === "about" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("about")}
            >
              <Link to="/about">ABOUT PROJECT</Link>
            </button>
          </li>
          <li>
            <button
              className={`buttons ${clickedButton === "plans" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("plans")}
            >
              <Link to="/masterplans">MASTER PLANS & FLOOR PLANS</Link>
            </button>
          </li>
          <li>
            <button
              className={`buttons ${clickedButton === "amenities" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("amenities")}
            >
              <Link to="/amenities">AMENITIES & SPECIFICATIONS</Link>
            </button>
          </li>
          <li>
            <button
              className={`buttons ${clickedButton === "gallery" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("gallery")}
            >
              <Link to="/gallery">GALLERY</Link>
            </button>
          </li>
          <li>
            <button
              className={`buttons ${clickedButton === "contact" ? "clicked" : ""}`}
              style={{ height: "150%" }}
              onClick={() => handleClick("contact")}
            >
              <Link to="/contact">CONTACT US</Link>
            </button>
          </li>
        </ul>
      </nav> */}


    </div>
    
  );
};

export default Header;


