import React from 'react'
import { useEffect,useState} from 'react';
import Footer from './Footer';
const Amenities = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 9);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>

    {/* <section className="slider" id="appetizer123" style={{backgroundColor:"rgb(241, 234, 234)"}}> */}
  {/* <div className="container">
    <div className="row">
      <div className="col-md-11">
        <div className="carousel" id="main-slider">

        <div className="carousel slide" data-ride="carousel" id="carousel-example-generic">
      <div className="carousel-inner"> */}
      <img  alt=""  
    src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/amenities.jpg" title=""  style={{width:"1300px",marginLeft:"30px",height:"500px"}}></img>
      

        {/* <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/amenities.jpg" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img12.jpg" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img13.jpg" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 3 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img14.jpg" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 4 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img15.jpg" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 5 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img16.png" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 6 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img17.png" alt="" height="550" width="130%" />
        </div> */}
        {/* <div className={`carousel-item ${activeIndex === 7 ? 'active' : ''}`}> */}
          {/* <img className="d-block w-100" src="/images/img18.png" alt="" height="550" width="130%" /> */}
          {/* <div className={`carousel-item ${activeIndex === 8 ? 'active' : ''}`}>
          <img className="d-block w-100" src="/images/img20.jpg" alt="" height="550" width="100%" />
        </div> */}
        {/* </div>
      </div>
      <ol className="carousel-indicators"></ol>
    </div>
        </div>
      </div>
      </div>
      </div> */}
     {/* </section> */}

<section className="page-section pb100" style={{background: "#f1eaea",padding:"15px",margin:"2px"}}>
<div className="line-container project-brief fw300"> 
<div className="row">
<div className="col-md-12">
<div className="title-gap">
<h1 className="chead">
<img src="" />
<span> AMENITIES </span></h1></div></div></div>
<div className="row pt20" style={{position: "relative"}}>
{/* <div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src=""/></p>

<h5> Grand Entry Lobby </h5></div></div>
<div className="col-lg-3"><div className="amen">
<p>
<img alt="" className="icons" src=""/></p>
<h5>Multipurpose Hall </h5></div></div> */}
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src=""/></p>

<h5> Fully Equipped Air-<br/>Conditioned Gymnasium </h5></div></div>
<div className="col-lg-3"><div className="amen">
<p>
<img alt="" className="icons" src=""/></p>
<h5>Aerobics Hall </h5></div></div>
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src=""/></p>
<h5> Yoga / Meditation Room </h5></div></div>
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src=""/></p>
<h5> Swimming Pool/Kids pool </h5></div></div></div>
<div className="row">
  <div className="col-lg-3">
<div className="amen"><p>
<img alt="" className="icons" src="assets/img/amenities/Spa (Saloon, Massage 
Room,_Steam - Sauna, Treatment Rooms).svg"/></p><h5> Billiards
 </h5></div></div>
<div className="col-lg-3"><div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Badminton Courts.svg"/></p>
<h5> Chess & Carom Board</h5></div></div>
<div className="col-lg-3">
<div className="amen">
  <p>
<img alt="" className="icons" src="assets/img/amenities/Squash Court.svg"/></p>
<h5> Pool Table </h5></div></div>
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Table Tennis.svg"/></p>
<h5> ATM'S </h5></div></div></div>
<div className="row" style={{position: "relative"}}>
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Billiards.svg"/></p>
<h5>Project Maintenance Office</h5></div></div>
<div className="col-lg-3"><div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Chess &amp; Carom Board.svg"/></p>
<h5>Creche</h5></div></div>
<div className="col-lg-3">
<div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Pool Table.svg"/></p>
<h5> Entrance Lobbies </h5></div></div><div className="col-lg-3">
<div className="amen">
  <p>
<img alt="" className="icons" src="assets/img/amenities/Basketball Court.svg"/></p>
<h5> 11 Passenger <br/> and 5 Service Lifts. </h5></div></div></div>
<div className="row" style={{position: "relative"}}>
{/* <div className="col-lg-3"><div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Volley Ball Court.svg"/></p>
<h5>  Creche</h5></div></div> */}
 {/* <div className="col-lg-3">
<div className="amen">
  <p>
<img alt="" className="icons" src="assets/img/amenities/Cricket Practice Nets.svg"/></p>
<h5> Cricket practice nets </h5></div></div> */}
<div className="col-lg-3">
  <div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Skating Ring.svg"/></p>
<h5> Multi Purpose Court </h5></div></div>
<div className="col-lg-3">
  <div className="amen">
<p>
<img alt="" className="icons" src="assets/img/amenities/Rock Climbing.svg"/></p>
<h5> Outdoor fitness Zone </h5></div></div></div>
<div className="row">


                                </div>
                                <div className="row" style={{ position: "relative"}}>
                                  <div className="col-lg-3">
                                    <div className="amen">
                                      <p>
                                      <img alt="" className="icons" src="assets/img/amenities/Outdoor 
                                      Fitness_Station.svg"/></p>
                                      {/* <h5> Outdoor fitness Zone </h5> */}
                                      </div></div>
                                      <div className="col-lg-3">
                                        <div className="amen">
                                          <p>
                                          <img alt="" className="icons" src="assets/img/amenities/Jogging-Running-Track.svg"/></p>
                                          {/* <h5> Temperature Controlled Pool </h5> */}
                                          </div></div>
                                          <div style={{clear:"both"}}></div></div></div>
                                          <div className="line-one ltgray-one"></div>
                                          <div className="line-two ltgray-two"></div>
                                          <div className="line-three ltgray-three"></div>
                                          <div className="line-four ltgray-four"></div>
                                          <div className="line-five ltgray-five"></div>
                                          </section>
<div style={{backgroundColor:"#f1eaea"}}>
<section className="page-section pb100 pt150 mob-pt50" style={{marginLeft:"20%",padding:"5px",
borderBlockColor:"black",marginRight:"20%",backgroundColor:"#f1eaea",width:"60%"}}>
<div className="line-container project-brief livelarge">
<div className="row">
<div className="col-md-12">
<div className="title-gap">
<h3 className="chead">
<img src="/images/circle-head.png"
style={{width: "8%",display: "inline-block",paddingLeft:"5px",backgroundColor:"#f1eaea",mixBlendMode:"multiply" }}/>
<span className="black" style={{ marginRight:"30%"}}> SPECIFICATIONS </span></h3></div></div></div>
<div className="row dflex">
<div className="col-lg-12 line-pad aboutbrief text-justify">
<div className="brief-content pl15">
<table className="table-specifications">
<tbody  >
<tr className="shead">
<td width="250" style={{backgroundColor:"",padding:"10px"}}> ITEM OF WORK </td>
<td > DESCRIPTION </td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> RCC Framed Structure </td>
<td> <p>RCC framed structure and Post-
Tensioning beams and Flat slabs
with drop panels for
Basement+G+13 floors and
Shear walls for Basement.</p> </td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> Masonry Walls</td>
<td>AAC Block masonry for external
and internal walls of G+13 floors. </td></tr>
<tr className="shead">
<td colspan="2" style={{padding:"10px"}}> Plastering </td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Internal </td>
<td><p> Cement based putty of approved
make for Internal walls &amp; Ceiling.</p></td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> External </td>
<td> Cement plastering for External walls. </td></tr>
<tr className="shead">
<td colspan="2"style={{backgroundColor:"",padding:"10px"}}> Painting</td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Internal </td>
<td><p> Two coats with Acrylic Emulsion
paint interior grade over cement
primer interior</p> </td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> External </td>
<td> Two coats with Acrylic Emulsion
paint exterior grade over cement
primer exterior </td></tr>
<tr className="shead">
  <td colspan="2" style={{backgroundColor:"",padding:"10px"}}> Doors </td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Main Door </td>
<td><p> Frame:- Double leaf using Best
Indian Teak wood frames of size
75 mm x 100 mm.<br/>
Shutter:-Double leaf 35 mm thick flush door
shutter, Solid
bond wood block board type with
teak ply on both faces.</p> </td></tr>

<tr className="bg2">
<td style={{padding:"10px"}}> Internal Doors </td>
<td> Frame:- Single leaf using Best
Indian Teak wood frames of size
75 mm x 100 mm. <br/>
Shutter:- Single leaf 35mm thick flush door
shutter, Solid
bond wood block board type with
teak ply on both faces. </td></tr>

<tr className="bg1">
<td style={{padding:"10px"}}> Toilets, Utility area &amp; Doors </td>
<td><p> Frame:- Solid Wood Polymer
Composite (WPC) single
extruded Door .5 mm . <br/>
Shutter:- 28 -30 mm thick Solid
Wood Polymer Composite
(WPC)  </p> </td></tr>
<tr className="bg2">
  <td style={{padding:"10px"}}> Windows</td>
<td><p> UPVC white colour Three track
three panels sliding window with
fly proof SS wire mesh(Two nos.
glazed &amp; one no. wire mesh
panels).</p> </td></tr>

<tr className="bg2">
  <td style={{padding:"10px"}}> Ventilators </td>
<td> Fixed Louvered Ventilator made
out of multi chambered UPVC
sections </td></tr>

<tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> Grills & Railings </td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Safety Grills for Windows </td>
<td> <p>Safety grills for Windows &amp; Utility
area </p></td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> Railings </td>
<td> SS railing for staircases </td></tr>
{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> FLOORING </td></tr> */}
<tr className="bg1">
<td> FLOORING </td>
{/* <td><p> 900 x 900 mm size and thickness
between 9-11 mm of 1st quality
digital / polished glazed full
body porcelain vitrified tiles in
Living room, Bed room, Kitchen,
Balcony, Pooja room, Dinning
room and Hand wash area.</p> </td> 
</tr>
<tr className="bg2">
{/* <td> </td> */}
<td><p> skirting to internal walls with
digital/polished glazed full body
porcelain vitrified tiles of size 900
x 900 mm and thickness between
9-11 mm in Skirting to internal
walls of Living room, Bed room,
Kitchen, Balcony, Pooja room,
Dinning room and Hand wash
area.</p></td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> For Toilets &amp; Utility Area </td>
<td><p> First Quality Non-skid red or white full body
Ceramic floor tiles of size 300 x
300 mm and thickness between 7-
8 mm in Toilets &amp; Utility
area  </p></td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> Dadoo</td>
<td><p> Sides of walls with First Quality glazed red or
white full body ceramic wall
tiles of size 300 x 600 mm and
thickness between 6-8 mm  in Toilets, Utility &amp; Above
kitchen platform </p></td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Common Areas </td>
<td><p>1. 16 to 18 mm thick Leather
Finished granite slabs up to 8&#39;-
00 in Corridors , Staircase, Lobby
area, Entrance lobby areas</p><br/> 
<p>2. Skirting to internal walls/risers of
steps with 8 mm thick mirror
polished granite tiles For risers
and Skirting in Corridors ,
Staircase, Lobby area, Entrance
lobby areas.</p> </td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Kitchen Platform </td>
<td><p> 16 to 18 mm thick High polished
Granite stone slabs, other than
black with regular colours over CC
platform in Kitchens</p> </td></tr>
<tr className="bg2">
<td style={{padding:"10px"}}> Bathroom
European Water Closet </td>
<td><p> floor mounted Close coupled
EWC suit CASCADE model &#39;P&#39; or
&#39;S&#39; trap with dual flush porcelain
cistern of standard make</p>
</td></tr>
<tr className="bg1">
<td style={{padding:"10px"}}> Wash Hand Basin </td>
<td> Indian make First Quality Flat Back Wash
Hand Basin of 
standard.</td></tr>

<tr className="shead">
  <td colspan="2" style={{backgroundColor:"",padding:"10px"}}> Electrical Fixtures and Cables</td></tr>
<tr className="bg1">
<td>Light Points </td>
<td><p> One Light point for every 70 sqft  built up area for each flat. Two way light points, two numbers in  each Bedroom.</p> </td></tr>
<tr className="bg2"><td>Fan Points</td>
<td><p> One point each in drawing, living, all bedrooms, Dining & kitchen</p> </td></tr>
<tr className="bg1">
<td>Exhaust Fan Point</td>
<td><p> In all toilets and kitchen.
</p> </td></tr>

<tr className="bg1">
<td>Bell</td>
<td><p> One call bell point with buzzer outlet in Living Room.</p></td></tr>
<tr className="bg1">
<td> 5 amps sockets </td>
<td> 2 Nos  in each bedroom,1 No. in each toilet, 2 Nos in dining, 2 Nos in drawing, 1 each in balcony  and 3 Nos in kitchen. </td></tr>
<tr className="bg1">
<td>15 amps sockets  </td>
<td> 6 Nos in kitchen and utility area (for refrigerator, washing machine, oven and other kitchen appliances). </td></tr>
<tr className="bg1">
<td>20 amps sockets  </td>
<td>For AC Units in all bedrooms, living & drawing.</td></tr>

{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> TELECOM </td></tr> */}
<tr className="bg1">
<td>Telephone</td>
<td> One telephone point in Living
room. </td></tr>
{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> INTERCOM </td></tr> */}
<tr className="bg1">
<td>Intercom</td>
<td> Intercom wiring and point in living room connecting between all flats and security room.</td></tr>

{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> CABLE TV </td></tr> */}
<tr className="bg1">
<td>Cable TV</td>
<td> Conduit for TV cable in Living &amp;
Master bedroom. </td></tr>
{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> INTERNET </td></tr> */}
<tr className="bg1">
<td>Internet</td>
<td> Internet cable conduit provision in living room </td></tr>
{/* <tr className="shead">
<td colspan="2" style={{backgroundColor:"",padding:"10px"}}> LIFTS </td></tr> */}
<tr className="bg1">
<td>Lifts</td>
<td> 3 Lift cores each consist of 2
Passenger lifts &amp; 1 Service lift. </td></tr>

{/* <tr className="shead">
  <td colspan="2" style={{backgroundColor:"",padding:"10px"}}> GENERATORS </td></tr> */}
  <tr className="bg1">
    <td>Generators</td>
  <td> 100% power backup for common
areas, lifts and water motor
pumps.. </td></tr>
  {/* <tr className="shead">
    <td colspan="2" style={{backgroundColor:"",padding:"10px"}}> PARKING MANAGEMENT </td></tr> */}
    <tr className="bg1">
      <td>Parking Management</td>
      <td> Entire parking is well designed as per mandatory requirement for Commercial, Office and Residential units with provision for charging points for EV vehicles.</td></tr>
                  {/* <tr className="shead">
                    <td colspan="2" style={{backgroundColor:"",padding:"10px"}}> FIRE AND SAFETY </td></tr> */}
                    <tr className="bg1">
                      <td>Fire And Safety</td>
                      <td> Fire hydrants, Fire sprinkler
system,
Fire alarm and Public addressing
system as per NBC norms. </td></tr>
                                
     </tbody></table></div></div></div></div>
    <div className="line-one gray-one"></div>
     <div className="line-two gray-two"></div>
  <div className="line-three gray-three"></div>
    <div className="line-four gray-four"></div>
    <div className="line-five gray-five"></div></section></div>
 <Footer/>
     </div>
   
  )
}

export default Amenities
