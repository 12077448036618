import React from 'react';
import { useEffect,useState} from 'react';
import Footer from './Footer';
// import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import CustomDialog from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './App.css';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';
import './Home.css'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";


const Home = () => {

  const navigate=useNavigate();

  const [isOpen1, setIsOpen1] = useState(false);

  const handleImageClick = () => {
    setIsOpen1(true);
  };

  const handleClose = () => {
    setIsOpen1(false);
  };

  const openTudaapWebsite = () => {
    window.open('https://www.tudaap.in', '_blank');
  };

  const [form, setForm] = useState({});
  const setField=(field,value)=>{
    setForm({
       ...form,
       [field]:value
    })
  }

  const [lightboxOpen, setLightboxOpen1] = useState(false);
const [selectedImage, setSelectedImage] = useState("");
const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const images = [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/8+2+BHK+East+Facing+1.png", //1

    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/2bkh/11+2BHK+West+Facing+2.png",  //2
  
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/14+3BHK+East+Facing+1+F.png", //3
  
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/3bhk/13+3+BHK+West+Facing+2F.png", //4
 
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/4bhk/16+4BHK+F.png", //5
 
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/Ground+Floor+Plan.png", //6
 
   "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/1st+floor+plan.png", //7

   "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/2nd+Floor+plan.png", 

   //8

    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/3rd+Floor+plan.png", //9
 
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/floor+plans/4th+Floor+plan.png", //10

  
  ];

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  //   console.log(formData,"formdata")
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
  //  Perform login or registration logic here
  console.log(form);
  };


// const [isOpen, setIsOpen] = useState(false);
// const [selectedImage, setSelectedImage] = useState('');

// useEffect(() => {
//   const storedImage = localStorage.getItem('selectedImage');
//   if (storedImage) {
//     setSelectedImage(storedImage);
//     setIsOpen(true);
//   } else {
//     openDialog(''); 
//   }
// }, []);

// const openDialog = (image) => {
//   setSelectedImage(image);
//   setIsOpen(true);
//   localStorage.setItem('selectedImage', image);
// };



// const closeDialog = () => {
//   setSelectedImage('');
//   setIsOpen(false);
//   localStorage.removeItem('selectedImage');
// };

// const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

const [isOpen, setIsOpen] = useState(false);
// const [selectedImage, setSelectedImage] = useState('');
useEffect(() => {
  setIsOpen(true);
}, []);

const closeModal = () => {
  setIsOpen(false);
};



  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


  // useEffect(() => {
  //   handleShow();

  //   return () => {
  //   };
  // }, []);

 const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  return (

    <div style={{backgroundColor:"#f1eaea"}}>

{/* <Modal show={isOpen} onHide={closeModal} centered>


      <Modal.Header closeButton style={{height:"10vh"}}>

    
        <Modal.Title  style={{width:"90%",marginTop:"10px"}}><marquee>TUDA Towers</marquee></Modal.Title><br/>
      

      </Modal.Header>
      <Modal.Body>

      <div style={{display:"flex",justifyContent:"center",background:"#b6b2eb",margin:"5px"}}>
        <a href="https://tudaproperty.auctiontiger.net/EPROC" target="_blank" style={{background:"#b6b2eb",fontWeight:"bold",color:"black"}}>
          e-Auction Portal 
      <img src="images/new_blink.gif"  style={{width:"50px", height:"40px"}}>
        </img> </a></div> 
        <div style={{display:"flex",justifyContent:"center",background:"#b6b2eb",marginLeft:"5px"}}>  
        <a href="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/Auction.png" target="_blank" style={{background:"#b6b2eb",color:"black",fontWeight:"bold"}}>Auction Schedule 
        <img src="images/new_blink.gif" width="50" 
       height="40" style={{width:"50px", height:"40px"}}></img><br/>

    
       
        </a><br/><br/></div>

      <div class="row col-md-12">
       <center><h4>Details</h4></center> 
      </div>

        <div style={{display:"flex",justifyContent:"space-between",background:"#b6b2eb",margin:"5px"}}>

<div><a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Commerical.pdf' target='__blank' style={{fontSize:"16px",background:"#b6b2eb",fontWeight:"bold"}}>Commercial Schedule</a></div>
<div style={{marginLeft:"25px"}}><a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Office+Space.pdf' target='__blank' style={{fontSize:"16px",background:"#b6b2eb",fontWeight:"bold"}}>Office Schedule</a></div>

<div style={{marginLeft:"25px"}}><a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda_latest_Schedule_Residential.pdf' target='__blank' style={{fontSize:"16px",background:"#b6b2eb",fontWeight:"bold"}}>Residential Schedule</a></div>
</div>
<div style={{display:"flex",justifyContent:"center",background:"#b6b2eb",margin:"5px"}}>
        <a href="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/pricing/TUDA+Bidding+Process.pdf" target="_blank" style={{background:"#b6b2eb",fontWeight:"bold",fontSize:"16px",background:"#b6b2eb",fontWeight:"bold"}}>
         Bidding Process
       </a></div> 
       
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/q3O46camKyg" title="TUDA Towers 
      Tirupati | Building A Future That Inspires | Walkthrough Video" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; 
      web-share" allowfullscreen></iframe>
      </Modal.Body>
    </Modal> */}



   {/* <section className="slider" id="appetizer123" style={{backgroundColor:"#f1eaea"}}>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="carousel" id="main-slider">

        <div className="carousel slide" data-ride="carousel" id="carousel-example-generic">
      <div className="carousel-inner">

        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          <img  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/tuda+aerial+face+hill+200722.png" alt="" style={{height:"600", width:"100%"}} />
        </div>
        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          <img  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/home+2.png" alt="" style={{height:"600", width:"100%" }} />
        </div>
        <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
          <img  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/home+page+slider+1.png" alt="" style={{height:"600", width:"100%" }}/>
        </div>
      
      </div>
      <ol className="carousel-indicators"></ol>
    </div>
        </div>
              
              

              
      </div>
      </div>
      </div>
     </section> */}

   <iframe width="100%" height="500" src="https://www.youtube.com/embed/q3O46camKyg" title="TUDA Towers 
      Tirupati | Building A Future That Inspires | Walkthrough Video" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; 
      web-share" allowfullscreen></iframe>


<div className="timer-before-after" >
      <div className="showbook" style={{backgroundColor:"#f1eaea"}}>
        <h3>
          {/* <a
            className="countdown-booknow"
            routerlink="/intrest"
            href="/intrest"
            style={{
              // textAlign: "center",
              marginLeft: "35%",
              backgroundColor: "pink",
              color: "blue",
              height: "3vh",
              animation: "blinking 2s infinite",
              borderRadius: "5px"
            }}
          >
           <button className="buttons1" style={{ height:"42px",width:"300px"}}><h3><b >SUBMIT INTEREST</b></h3></button> 
          </a> */}
            
          <div  style={{display: "flex",
    justifyContent: "space-around"}}>
          {/* <Button   style={{color:"black"
          ,animation: "blinking 2s infinite",marginTop:"10px"}}>
           </Button> */}
          
     {/* <a href='https://tudaproperty.auctiontiger.net/EPROC/'  style={{color:"black"
          ,animation: "blinking 2s infinite"}} target='__blank'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index-thumb-fill" viewBox="0 0 16 16">
          <path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z"/>
        </svg> 
          REGISTRATION</a> */}
      </div>
      
    {/* <marquee class="scroll-text" onmouseout="this.start();" onmouseover="this.stop();" scrollamount="6" scrolldelay="0"><span > &nbsp;&nbsp; ** &nbsp;&nbsp;</span> Registrations Opens on 7th July 2023;<span > &nbsp;&nbsp;  </span> E- Auctions will be from 19th July 2023 onwards;<span > &nbsp;&nbsp;  </span> Online Payments of Registration amount/Booking Advance can be made from 15th July and to be completed before 24 hours in advance before auction; Login details will be shared via email  <span > &nbsp;&nbsp; ** &nbsp;&nbsp;</span></marquee> */}
{/* 
    <marquee class="scroll-text" onmouseout="this.start();" onmouseover="this.stop();" scrollamount="6" scrolldelay="0"><span> ** Registered users please check your email id for further details.<span>Please reset the password, Once you login to  <a href="https://tudaproperty.auctiontiger.net/EPROC/" target="_blank">"https://tudaproperty.auctiontiger.net/EPROC/"</a>  You can proceed with payment of EMD's now.</span>



</span>
</marquee> */}



{/* <marquee class="scroll-text" onmouseout="this.start();" onmouseover="this.stop();" scrollamount="6" scrolldelay="0"><span> **  Auction Schedules are live now in <a href="https://tudaproperty.auctiontiger.net/EPROC/" target="_blank">"https://tudaproperty.auctiontiger.net/EPROC/"</a> and EMDs can be made now ** </span></marquee> */}
<p></p>
<p></p>
<marquee class="scroll-text" onmouseout="this.start();" onmouseover="this.stop();" scrollamount="6" scrolldelay="0"><span> 
{/* **
Auction for Apartments in TUDA Towers will be live from 23rd July 2023 be ready by paying Registration and EMD payments to grab the opportunity ** */}

**
All successfull bidders should have received your allotment letter and credentails to login to your account in <a href="https://user.tudatowers.in/" target="_blank">"https://user.tudatowers.in/"</a> to your email id. Please write back to <a href="vctuda@gmail.com" target="_blank">"vctuda@gmail.com"</a> for any further queries  **</span></marquee>
    <div style={{display: "flex",
    justifyContent: "space-around"}}> 
        <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda+Brocher.pdf' target='_blank'>
          DOWNLOAD BROCHURE
        </a>
      </div>
   
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{height:"80px"}}>
          {/* <img src="images/logo.jpg" style={{width:"100px",marginLeft:"40%",marginBottom:"70px"}}></img><br/> */}
          <p style={{marginBottom:"7%",marginLeft:"40%"}}><b>WECOME TO</b></p><br/>
          <Modal.Title style={{marginBottom:"-1%",marginLeft:"-27%"}}><br/><b>TUDA TOWERS</b></Modal.Title>
        </Modal.Header>
        <Modal.Body><br/>
        <Form onSubmit={handleSubmit}>
          <Form.Group >
            <Form.Label>Name (As per Aadhar)</Form.Label>
            <Form.Control type="text" placeholder="Enter Name" value ={form.name} onChange={(e)=>setField('name',e.target.value)}  style={{height:"35px"}}/>
            <Form.Label>Enter Aadhar Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Aadhar Number"  style={{height:"35px"}}
              value ={form.aadhar} onChange={(e)=>setField('aadhar',e.target.value)}

            />
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Mobile Number"  style={{height:"35px"}}
              value ={form.mobile} onChange={(e)=>setField('mobile',e.target.value)}
            />
            <Form.Label>Email ID</Form.Label>
            <Form.Control type="email" placeholder="Enter Email"          
             value ={form.email} onChange={(e)=>setField('email',e.target.value)} style={{height:"35px"}} />
            <Form.Label>Interested In</Form.Label>
            <Form.Select  onChange={(e)=>setField('interested',e.target.value)}>
              <option value="2BHK">2BHK</option>
              <option value="3BHK">3BHK</option>
              <option value="4BHK">4BHK</option>
            </Form.Select>
          </Form.Group>
          <Button type="submit" className="buttons1"  style={{margin:"20px",marginLeft:"40%",color:"black"}}>
        <b>SUBMIT</b> 
        </Button>
        </Form>
      </Modal.Body>
    </Modal>
        </h3>
      </div>
    </div>

    <div className="row">
 

    <div className="title-gap welcome-head" data-animation="fadeInUp" style={{backgroundColor:"#635b5b",color:"blue",padding:"10px"}}>
       {/* <h2 className="title wc-slash123 animated fadeInUp visible" style={{marginLeft:"35%"}}><b>ABOUT TUDA</b><span className="down"><b> PROJECT</b></span></h2> */}
     </div>
   <div  style={{backgroundColor:"#635b5b",color:"white"}}>
  <h4 style={{marginLeft:"50px"}}>PROJECT HIGHLIGHTS</h4> 
 <div style={{padding:"10px,160px",marginLeft:"50px",marginRight:"50px"}}>
 <ul>
   <li>TUDA Towers consists of a basement parking along with a G+13 structure consisting of a mix of commercial zone, Office spaces, and Residential zones,</li>
Commercial/Office Schedule   <li>An outdoor swimming pool, indoor gym, jogging track, function hall, and SKY GARDENS at Floor 6, Floor 11 and Terrace with landscaped gardens and seating overlooking the Tirumala divine hills have been proposed.</li>
   <li>Office spaces are proposed from the level 3 floor to the level 5 floor in the southern block of the building creating a Walk to work culture.</li>
   <li>A viable solution wherein integrating the culture and environment of an office workspace into the housing complex – Walk to work.</li>
   <li>The ground and 1st floor comprise the commercial zone including shops and other amenities like restaurants, food courts, and banquet halls.</li>
   <li>The building is installed with a BMS system, fire alarm systems, and solar panels for energy efficiency.</li>
   <li>LOCATION: Annamaiah Circle, Tirupati.</li>
   <li>TOTAL SITE AREA: 3.61 Acres or 14,600 sq.m</li>
   <li>Three exclusive drop-offs to commercial, residential, and office spaces.</li>
   <li>Payment in instalments over a period of 24 months commensurating the construction.</li> 
   <li>
Defect Liability Period for R.C.C frame work is for  5 years and balance civil, Sanitary, Electrical, Firefighting  services are  for 2 years </li>

<li>
	The structure is designed for Seismic (Earthquake) loads – Zone III, as per Indian standard codes.
</li>
 </ul>
 
 </div></div>
       </div>
 <br></br><br>
 
 </br>



      {/* <section className="page-section ptn" style={{backgroundColor:"pink",height:"600%"}}>
  <div className="container-fluid line-container">
    <div className="row fact-counter phn" data-animation="fadeInUp">
   
      <div className="col-6 col-sm-6 col-md-2"  style={{marginLeft:"10%"}}>
        <div className="count-number counter-slash">
          <span className="counter">230</span>
          <h5>Units</h5>
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-2">
        <div className="count-number counter-slash">
          <span className="counter extra">3.61</span>
          <h5>Acres</h5>
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-2">


        <div className="count-number counter-slash">
          <span className="counter">1</span>
          <h5>Towers</h5>
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-2">
        <div className="count-number counter-slash">
          <span className="counter">13</span>
          <h5>Floors</h5>
        </div>
      </div>
    </div>
    </div>


</section> */}

{/* <div className='row'>
  <div  className='col'>
230


  </div>

  <div  className='col'>3.61</div>


  <div  className='col'>1</div>

  <div  className='col'>1</div>


</div>
<div className='row'>
  <div  className='col-md-3'>
  Units


  </div>

  <div  className='col-md-3'>Towers</div>


  <div  className='col-md-3'>Floors</div>

  <div  className='col-md-3'>1</div>


</div> */}

<div className="row" style={{padding:"40px",margin:"0px"}}>
  <div className="col-md-12">
    <div className="line-pad posr" data-animation="fadeInUp">
      <div className="title-gap pb30 mob-pbn">
      </div>

      <div className=" ">
  <table className="table table-specifications hyperlinks table-bordered responsive" style={{ width:"50% !important",marginLeft:"-12px",marginRight:"20px"}}>
    <thead>
      <tr className="table-heading" style={{backgroundColor:"#b6b2eb"}}>
        <th style={{backgroundColor:"#b6b2eb"}}> Units</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Acres</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Towers</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Floors</th>
       
      </tr>
    </thead>
    <tbody className="table-body">
      <tr className="bg1">
        <td>230 Residential; 27 Commercial; 12 Office </td>
        <td>3.61</td>
        <td>01</td>
        <td>G+13</td>
      </tr></tbody>
      </table>
</div></div></div>

<div className="row" style={{padding:"40px"}}>
  <div className="col-md-12">
    <div className="line-pad posr" data-animation="fadeInUp">
      <div className="title-gap pb30 mob-pbn">
        <h3 className="chead" style={{marginLeft:"17%",marginRight:"10%"}}>
          <img src="/images/circle-head.png" style={{width:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
          {/* <a href="/masterplans" > (For More Details Click Here) </a> */}
          <span className="black" >FLATS CONFIGURATIONS <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda_latest_Schedule_Residential.pdf' target='__blank'>Flats Auction Schedule</a>  </span>
        </h3>
      </div>

      <div className=" ">
  <table className="table table-specifications hyperlinks table-bordered responsive" style={{ width:"50% !important",margin:"auto"}}>
    <thead>
      <tr className="table-heading" style={{backgroundColor:"pink"}}>
        <th style={{backgroundColor:"#b6b2eb"}}>Saleable Area (sqft)</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Unit Type</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Total No of Units</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Plan</th>
       
      </tr>
    </thead>
    <tbody className="table-body">
      <tr className="bg1">
        <td>1211 </td>
        <td>2 BHK 2nd to 13th Floor (East Facing)</td>
        <td>23</td>
        <td>

        {/* <a href="/masterplans" target='__blank'> (For More Details Click Here) </a> */}
            {/* <button  onClick={(e) => {
              e.preventDefault();
              // openLightbox(0);
            }} style={{padding:"4px"}}><a href="/masterplans" > (For More Details Click Here) </a></button> */}
          </td>
      </tr>
      <tr className="bg2">
        <td>1211 </td>
        <td>2 BHK 2nd to 13th Floor (West Facing)</td>
        <td>23</td>
        <td>
            {/* <button onClick={(e) => {
              e.preventDefault();
              openLightbox(1);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
    </tr>
            <tr className="bg1">
              <td>1865 </td>
              <td>3 BHK 2nd to 13th Floor (East Facing)</td>
              <td>76</td>
              <td>
            <button onClick={(e) => {
              e.preventDefault();
             // openLightbox(2);
             navigate("/masterplans")
            }} style={{padding:"4px"}}>Click here</button>
              {/* <a href="/masterplans" target='__blank'> (For More Details Click Here) </a> */}
          </td>
            </tr>
            <tr className="bg2">
              <td>1865 </td>
              <td>3 BHK 2nd to 13th Floor (West Facing)</td>
              <td>76</td>
              <td>
            {/* <button onClick={(e) => {
              e.preventDefault();
              openLightbox(3);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>

            </tr>
            <tr className="bg2">
              <td>3743 </td>
              <td>4 BHK 6th to 13th Floor (North Facing)</td>
              <td>32</td>
              <td>
            {/* <button onClick={(e) => {
              e.preventDefault();
              openLightbox(4);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
            </tr>
   
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div className="row" style={{padding:"40px"}}>
  <div className="col-md-12">
    <div className="line-pad posr" data-animation="fadeInUp">
      <div className="title-gap pb30 mob-pbn">
        <h3 className="chead" style={{marginLeft:"17%",marginRight:"10%"}}>
          <img src="/images/circle-head.png" style={{width:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
          {/* <span className="black" >COMMERCIAL CONFIGURATIONS   
         <span> </span> <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda_latest_Schedule_Commerical.pdf' target='__blank'>  Commercial Auction Schedule</a>
          </span> */}

          <span className="black" >COMMERCIAL CONFIGURATIONS   
         <span> </span> <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Commerical.pdf' target='__blank'>  Commercial Auction Schedule</a>
          </span>
        </h3>
      </div>

      <div className=" ">
  <table className="table table-specifications hyperlinks table-bordered responsive" style={{ width:"50% !important",margin:"auto"}}>
    <thead>
      <tr className="table-heading" style={{backgroundColor:"#b6b2eb"}}>
        <th style={{backgroundColor:"#b6b2eb"}}>Saleable Area (sqft)</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Unit Type</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Total No of Units</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Plan</th>
       
      </tr>
      </thead>
    <tbody className="table-body">
    <tr><th>GROUND-FLOOR</th>
<th></th>
<th></th>
<th></th></tr>

      <tr className="bg1">
        <td>4919 </td>
        <td>Shop S-G01 (East)                          
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(6);
            }} style={{padding:"4px",borderRight: "none"}}></button> */}
          </td>
      </tr>

      <tr className="bg2">
        <td>4770 </td>
        <td>Shop S-G02 (East)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(7);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>
 
      <tr className="bg1">
        <td>3229 </td>
        <td>Shop S-G03 (East)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(8);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>    
         
      <tr className="bg1">
        <td>2562 </td>
        <td>Shop S-G05 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(9);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2218 </td>
        <td>Shop S-G06 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(10);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>1597 </td>
        <td>Shop S-G07 (East)
</td>
        <td>01</td>
        <td>
            <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(5);
            }} style={{padding:"4px",border:"none"
          }}>Click here</button>
          </td>
      </tr>

      <tr className="bg1">
        <td>6305</td>
        <td>Shop S-G08 (East)
</td>
        <td>01</td>
        <td style={{border:"none"}}>
            {/* <button  onClick={(e) => {
              e.preventDefault();
               openLightbox(12);
            }} style={{padding:"4px"}}>Click here</button> */}


          </td>
      </tr>

      <tr className="bg1">
        <td>6462 </td>
        <td>Shop S-G09 (East)
</td>
        <td>01</td>
        <td style={{border:"none"}}>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(13);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>3050 </td>
        <td>Shop S-G10 (East)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(14);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

   

      <tr className="bg1">
        <td>2619</td>
        <td>Shop S-G11 (East & West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(15);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>
      <tr className="bg1">
        <td>4933 </td>
        <td>Shop S-G12 (East & West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(16);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>
<tr><th>FIRST-FLOOR</th>
<th></th>
<th></th>
<th></th></tr>
      <tr className="bg1">
        <td>3916 </td>
        <td>Shop S-101 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(17);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2607 </td>
        <td>Shop S-102 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>1898 </td>
        <td>Shop S-103 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(19);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>3333 </td>
        <td>Shop S-104 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(20);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>26868 </td>
        <td>Shop S-105 (East & West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(21);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2137 </td>
        <td>Shop S-106 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(22);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>1804 </td> 
        <td>Shop S-107 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(23);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg2">
        <td>1396 </td>
        <td>Shop S-108 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(7);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2711</td>
        <td>Shop S-109 (East)
</td>
        <td>01</td>
        <td>
            <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(6);
            }} style={{padding:"4px"}}>Click here</button>
          </td>
      </tr>

      <tr className="bg1">
        <td>2091 </td>
        <td>Shop S-110 (South)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(26);
            }} style={{padding:"4px"}}></button> */}
          </td>

      </tr>
      <tr className="bg1">
        <td>1084 </td>
        <td>Shop S-111 (East)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(27);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2596</td>
        <td>Shop S-112 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(28);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>3152 </td>
        <td>Shop S-113 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(29);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>2587</td>
        <td>Shop S-114 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(30);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

     
      <tr className="bg1">
        <td> 2618</td>
        <td>Shop S-115 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(31);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td> 3926
</td>
        <td>Shop S-116 (West)
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(32);
            }} style={{padding:"4px"}}></button> */}
          </td>
      </tr>



      </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div className="row" style={{padding:"40px",margin:"5p"}}>
  <div className="col-md-12">
    <div className="line-pad posr" data-animation="fadeInUp">
      <div className="title-gap pb30 mob-pbn">
        <h3 className="chead" style={{marginLeft:"17%",marginRight:"10%"}}>
          <img src="/images/circle-head.png" style={{width:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}></img>
        {/* <span></span>  <span className="black" >OFFICE CONFIGURATIONS <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Tuda_latest_Schedule_Commerical.pdf' target='__blank'>Office Auction Schedule</a></span> */}
        <span></span>  <span className="black" >OFFICE CONFIGURATIONS <a href='https://tudatowers-doc.s3.ap-south-1.amazonaws.com/documents/Office+Space.pdf' target='__blank'>Office Auction Schedule</a></span>
        </h3>
      </div>

      <div className=" ">
  <table className="table table-specifications hyperlinks table-bordered responsive" style={{ width:"50% !important",margin:"auto"}}>
    <thead>
    <tr className="table-heading" style={{backgroundColor:"#b6b2eb"}}>
        <th style={{backgroundColor:"#b6b2eb"}}>Super Buildup Area (sqft)</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Unit Type</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Total No 0f Units</th>
        <th style={{backgroundColor:"#b6b2eb"}}>Plan</th>
       
      </tr>
    </thead>
    <tbody className="table-body">
    <tr><th>SECOND-FLOOR</th>
<th></th>
<th></th>
<th></th></tr>
    <tr className="bg1">
        <td>7414 </td>
        <td>O-201 (North)                          
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>13434</td>
        <td>O-202 (North)                         
</td>
        <td>01</td>
        <td>
            <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(7);
            }} style={{padding:"4px"}}>Click here</button>
          </td>
      </tr>
      <tr className="bg1">
        <td>7413</td>
        <td>O-203  (North)                          
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>1712 </td>
        <td>O-204 (West)                        
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr><th>THIRD-FLOOR</th>
<th></th>
<th></th>
<th></th></tr>

      <tr className="bg1">
        <td>7414 </td>
        <td>O-301 (North)                       
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg2">
        <td>13434 </td>
        <td>O-302  (North)                            
</td>
        <td>01</td>
        <td>
            <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(8);
            }} style={{padding:"4px"}}>Click here</button>
          </td>
      </tr>

      <tr className="bg1">
        <td>7413 </td>
        <td>O-303 (North)                            
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>
      <tr className="bg1">
        <td>1712 </td>
        <td>O-304 (West)                          
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr><th>FOURTH-FLOOR</th>
<th></th>
<th></th>
<th></th></tr>

      <tr className="bg1">
        <td>7414 </td>
        <td>O-401 (North)                           
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(18);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>

      <tr className="bg1">
        <td>13434 </td>
        <td>O-402 (North)                           
</td>
        <td>01</td>
        <td>
            <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(9);
            }} style={{padding:"4px"}}>Click here</button>
          </td>
      </tr>

      <tr className="bg1">
        <td>7413</td>
        <td>O-403 (North) 
</td>
        <td>01</td>
        <td>
            {/* <button  onClick={(e) => {
              e.preventDefault();
              openLightbox(9);
            }} style={{padding:"4px"}}>Click here</button> */}
          </td>
      </tr>


      <tr className="bg1">
              <td>1712</td>
              <td>O-404 (West)</td>
              <td>01</td>
              <td style={{ borderRight: "none", border: "none"  }}>
               {/* <button  onClick={(e) => {
                  e.preventDefault();
                  openLightbox(21);
                }} style={{padding:"4px", border: "none" }}></button> */}
                 </td>
                 </tr>
   
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<div>

{isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxIndex]}
          nextSrc={images[(lightboxIndex + 1) % images.length]}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      )}
 
</div>
</div>

<div className="row pt50">
<div className="col-md-12">
  <div className="title-gap">
    <h3 className="chead">
      <img src="images/map-icon.jpg" alt="" style={{width:"3%",marginLeft:"10%",backgroundColor:"#e2dbdbfd"}}/>
      <span className="black">TUDA Towers Vicinity</span>
    </h3>
  </div>
</div>
</div>

      <div className="row" style={{ backgroundColor: '#e2dbdbfd' }}>
      <div className="col-md-12">
        <div style={{ border: '0', width: '80%', marginLeft: '10%', backgroundColor: '#e2dbdbfd' }}>
          <img
            onClick={handleImageClick}
            src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg"
            alt="TUDA Towers GMS"
            style={{ width: '100%', cursor: 'pointer' }}
          />
        </div>
      
      </div>
    </div>
    
    <div className="line-one ltgray-one"></div>
    <div className="line-two ltgray-two"></div>
    <div className="line-three ltgray-three"></div>
    <div className="line-four ltgray-four"></div>
    <div className="line-five ltgray-five"></div>
  
  {isOpen1 && (
          <Lightbox
            mainSrc="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg"
            onCloseRequest={handleClose}
          />
        )}
<Footer />
  </div> 

  )
}
export default Home;