import React from 'react'

const Option = () => {
  return (
    <div style={{backgroundColor:"#808080",margin:"0%",width:"100%",marginRight:"30%",marinLeft:"10%"}}>
        <div style={{backgroundColor:"#635b5b",margin:"0%",padding:"3%",width:"90%",marginRight:"10%",marginLeft:"5%",color:"white",fontSize:"18px"}}>
            <h3>Terms and Conditions</h3> 
       <h4> Overview </h4>

<p>The Terms and Conditions, specified hereunder, governs the use of the services of www.Procure247.com and its 
    sub-domains, under the brand name of “<i><i><b>Procure247 </b></i></i> ”, which is powered by <b> i-Sourcing Technologies Pvt. 
    Ltd.</b> (“<i><b>Procure247 </b></i> ”).</p>

<h4>Objective </h4>

<p>The main objective of <b><i><b>Procure247 </b></i>  </b> is to provide relevant information and services to our Clients and their respective Customers.</p>

<h4>Collection of Information </h4>

<p><i><b>Procure247 </b></i>  collects information from its users when they register to gain access to the services offered by <i><b>Procure247 </b></i>  or at other specific instances when they are requested to provide with their personal & business information.</p>

<h4>Sharing of Information </h4>

<p><i><b>Procure247 </b></i>  will share any information as sought by statutory bodies in compliance with the local applicable and governing laws where the operations of <i><b>Procure247 </b></i>  is in existence. While the data of the respective Users might be shared for providing information on other products and/ or services in the relevant industry, the User has the option to refrain from being provided with such information.</p>

<p>Any information collected from the User form the basis for data analytics for our research and also for the research undertaken by third parties from time to time.</p>

<h4>Protection of Information </h4>

<p><i><b>Procure247 </b></i> , to the best of its ability, will prevent any misuse of its data by any third party for any reason, whatsoever. In the event of any such unforeseen eventuality brought to the notice of <i><b>Procure247 </b></i> , the respective user will be notified of such misuse. To prevent any unauthorized access of their information, the Users are requested to sign out of their <i><b>Procure247 </b></i>  account (including that of the sub-domain from where they might have logged in) and close their browser window on completion of their activity. Any activity undertaken through the credentials (Mobile No./ Email-Id and Password) of the User will be considered to be an authorization by the respective user.</p>

<p><i><b>Procure247 </b></i>  will take all necessary steps, within its limits of commercial viability and necessity, to ensure that the user's information is treated securely.</p>

<h6>Note:</h6>

<p>Transmission of data over the internet from any device cannot be guaranteed to be fully secured. Hence, <i><b>Procure247 </b></i>  and ISTPL does not warranty the security of any information of the User which is transmitted to and/ or from <i><b>Procure247 </b></i> .</p>

<h4>Update of Terms & Conditions</h4>

<p><i><b>Procure247 </b></i> , powered by i-Sourcing Technologies Pvt. Ltd. (“<i><b>Procure247 </b></i> ”), reserves the right to change the Terms & Conditions at any time by posting the revised/ updated versions on the website. The revised/ updated versions will be effective immediately, unless specified otherwise, on publishing of the same on the website. Any access and/ or usage of the services of <i><b>Procure247 </b></i> , post revision/ update will be considered as the consent to the same. Users are recommended to check this Terms & Conditions regularly so as to be informed of any revision.</p>

<p>For any clarifications regarding the Terms & Conditions of <i><b>Procure247 </b></i> , contact us on sales@Procure247.com.</p>
    </div>  
    </div>
  )
}

export default Option
