
import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './Gallery.css';

const images = [
  [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg",
     
  ],
  [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF1.png",
     "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF1.png",
     "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF2.png",
  ],
  [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF6.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/swimming+pool/swimming+pool+2.jpeg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/swimming+pool/swimming+pool+3.jpeg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF1.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF4.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF5.png",
  
    
     
  ],
  [  
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/Tuda+top+view+200722.jpg",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/3d-total.png",
     "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/3d-front.png",
     "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/3d-side.png",
     "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/3d-back.png",
  ],
  [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg",
   
  ],
  [
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF2.png" ,
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF4.png",
    "https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF1.png"
  ]

];

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryIndex: 0,
      photoIndex: 0,
      isOpen: false
    };
  }

  handleImageClick = (categoryIndex, photoIndex) => {
    this.setState({
      categoryIndex,
      photoIndex,
      isOpen: true
    });
  };

  render() {
    const { categoryIndex, photoIndex, isOpen } = this.state;
    const categoryImages = images[categoryIndex];

    return (
     
          
          <div style={{backgroundColor:"rgb(241, 234, 234)"}}>
        {/* <button type="button" onClick={() => this.setState({ isOpen: true })}>
          Open Lightbox
        </button> */}
        <div class="container-fluid">
<div class="row"> 


<img  alt=""  
    src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/Gallery.png" title=""  style={{width:"1340px",marginLeft:"20px",height:"500px"}}></img>
 
</div>
 <div className="row"></div>
</div>

<div className="line-one ltgray-one"></div>
      <div className="line-two ltgray-two"></div>
      <div className="line-three ltgray-three"></div>
      <div className="line-four ltgray-four"></div>
      <div className="line-five ltgray-five"></div>
      <section className="page-section pb-100" style={{ background: '#fffcec',backgroundColor:"rgb(241, 234, 234)" }}>
      <div className="line-container project-brief fw300 black-text">
      <div className="row" >
          <div className="col-md-12" >
            <div className="title-gap">
              <h1 className="chead" style={{paddingTop:"100px"}}>
                <img src="images/circle-head.png" alt="Circle Head" style={{width:"4%",marginLeft:"6%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}/>
                <span className="black" style={{fontSize:"31px"}}> SELECT CATEGORY </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="line-one ltgray-one"></div>
      <div className="line-two ltgray-two"></div>
      <div className="line-three ltgray-three"></div>
      <div className="line-four ltgray-four"></div>
      <div className="line-five ltgray-five"></div>
        <div className="row pt-50" style={{paddingLeft:"100px"}}>
          <div className="col-lg-3" >
            <div className="home-gal" >
              <p  className="zoom-in"  onClick={() => this.handleImageClick(0, 0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/rear+view+27.jpg" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}} 
                />
              </p>
              <h5>MASTER LAYOUT</h5>
            </div>
          </div>
          <div className="col-lg-3" >
            <div className="home-gal" >
              <p  className="zoom-in" onClick={() => this.handleImageClick(1,0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF1.png" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}}
                />
              </p>
              <h5 style={{marginLeft:'40px'}}>AMENITIES</h5>
            </div>
          </div>
          <div className="col-lg-3" >
            <div className="home-gal" >
              <p  className="zoom-in"  onClick={() => this.handleImageClick(2,0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/SF6.png" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}}
                />
              </p>
              <h5>LANDSCAPE VIEWS</h5>
            </div>
          </div>
          <div className="col-lg-3" >
            <div className="home-gal">
              <p  className="zoom-in"  onClick={() => this.handleImageClick(3,0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/TUDA+VIEW+5+TOP.jpg" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}}
                /> 
              </p>
              <h5>3D PERSPECTIVE VIEWS</h5>
            </div>
          </div>
        </div>
        <div className="row pt-100" style={{paddingLeft:"100px"}}>
          <div className="col-lg-3">
            <div className="home-gal" >
              <p  className="zoom-in" onClick={() => this.handleImageClick(4,0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/maps/tuda+map.jpg" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}}
                />
              </p>
              <h5>LOCATION MAP</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="home-gal" > 
              <p  className="zoom-in" onClick={() => this.handleImageClick(5,0)}>
                <img
                  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/lobby%26sit+out/GF2.png" className="circle-image"
                  alt="" style={{width:"200px",height:"200px",borderRadius:"100%"}}
                />
              </p>
              <h5>ENTRANCE LOBBY VIEWS</h5>
            </div>
          </div>
        </div>
        <div className="row"></div>
      </div>
      <div className="line-one ltgray-one"></div>
      <div className="line-two ltgray-two"></div>
      <div className="line-three ltgray-three"></div>
      <div className="line-four ltgray-four"></div>
      <div className="line-five ltgray-five"></div>
      </section>
        
        {isOpen && (
          <Lightbox
            mainSrc={categoryImages[photoIndex]}
            nextSrc={categoryImages[(photoIndex + 1) % categoryImages.length]}
            prevSrc={categoryImages[(photoIndex + categoryImages.length - 1) % categoryImages.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + categoryImages.length - 1) % categoryImages.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % categoryImages.length
              })
            }
          />
        )}
        
      </div>
    );
  }
}

export default Gallery;