import React from 'react'
import Footer from './Footer';

const About = () => {
  return (
    <div>
      <img alt="" src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/about.png" title=""   
      style={{ display: 'block', margin: '0 auto',height:"100vh", width:"100%",backgroundColor:"#f1eaea", imageRendering: 'auto' }}></img>
      <section className="page-section pbn"
       style={{background:"#f1eaea",color:"black"}}
       >
      <div className="line-container project-brief" 
       style={{marginRight: "0px"}}
      >
      <div className="row">
        {/* <div className="col-md-8"> */}
      <div className="row pt30">
        <div className="col-lg-6 line-pad pr50 text-justify-spl">
          <div className="title-gap">
            <h1 className="chead">
              <img src="/images/circle-head.png" style={{width:"8%",marginLeft:"6%",backgroundColor:"#f1eaea",mixBlendMode:"multiply"}}/>
                <span> ABOUT PROJECT </span></h1></div></div></div>
                <div className="row pt20"  style={{paddingLeft:"50px"}}>
                  <div className="col-md-6 brief-content about-content">
                    <p style={{marginLeft:"20%",marginRight:"20%",textDecorationColor:"white"}}> Tirupati and Tirumala the abode of Lord Venkateswara is the most visited pilgrimage place and the "Spiritual capital of Andhra Pradesh".
The popularity as a pilgrim destination and the booming economy is making Tirupati a prime location for investments and TUDA towers offer the most idyllic location for investors to be part of a dynamic community. 
The city also emerged as a medical and education hub with prestigious institutes like IIT, IISER, Reputed universities, and medical & engineering schools.
The site is the city’s geographical centre on the cross of the 100’ wide AIR bypass Road and 60 wide Rayalacheruvu Road in close proximity to all city-level networks.
The project is located in a sprawling area of 3.61 acres in the heart of Tirupati and is the only such large site in Tirupati centre. The project is designed as a chill–work and ambient living centre. 
</p></div>
                         <div className="col-md-4 brief-content about-content pr50">
                          <p>
                            <span className="hightlight1">TUDA</span>  towers contain Commercial spaces spread over the Ground and First floors, Office spaces on 2nd,3rd 4th floors (Part) and well-crafted and vastu complied 46 Nos of 2 Bed apartments, 152 Nos of 3-bed apartments and 32 No’s of spacious 4 Bed Apartments. A gated community with diversified uses with well-integrated well-designed spaces for social interaction. 
The uniqueness of the project is the planned Sky gardens on the 6th,11th and Terrace to see the seven peaks of the Tirumala Hills and immerse in the holiness of Lord Venkateswara. 
 </p>
 <p>
  <span className="hightlight1">TUDA</span>  towers where luxury meets practicality, current trends meet tradition, where fine detailing is the norm and natural living blends with a smart lifestyle. This is where art, innovation, technology and passion intersect to create a world-class environment.
{/* <span className="hightlight1">TUDA</span> is 
  where every inch exudes pure joy. Joy that you can touch, feel, fully and truly 
    experience! */}
    </p></div></div></div><div className="col-md-6"></div></div>
    {/* </div> */}
  
  </section>

   <section className="page-section pb80" style={{background:"#f1eaea"}}>
   <img className="about-projecthighlight-building" src=""/>
   <div className="line-container project-brief123">
   <div className="row pt30">
    <div className="col-lg-6 line-pad pr50 text-justify-spl">
   <div className="title-gap">
    <h1 className="chead">
   <img src="/images/circle-head.png" style={{width:"8%",padbackgroundColor:"#f1eaea",mixBlendMode:"multiply",marginLeft:"5%"}}/>
   <span className="black" style={{padding:"19px"}}>PROJECT HIGHLIGHTS </span></h1></div></div></div>
   <div className="row pt20" style={{paddingLeft:"50px"}}>
   <div className="col-lg-6 line-pad aboutbrief123 pr100 text-justify-spl mob-prn">
   <div className="brief-content highlights123 pt30"><h5 className="ph-icons">
   <span>
    <img src="images/icon-6.png" style={{width:"8%"}}/>
      </span> LOCATION </h5>
   <p> Strategically located in the heart of Tirupati City,  Its Sri Venkateswara Temple sits
     a top one of the the 7 peaks of Tirumala Hills, attracting scores of Hindu pilgrims.
     Sri Venkateswara National Park, home to the temple, also contains the Sri Venkateswara 
     Zoological Park with lions and primates, 
   Media City &amp; Sports City.</p></div><div className="brief-content highlights123 pt50">
    <h5 className="ph-icons" style={{padding: "16px"}}><span>
      <img src="	images/icon-7.png" style={{width:"8%",marginLeft:"-15px"}}/>
      </span> AMENITIES </h5>
      <p> Club House comprising of fully equipped Air Conditioned Gymnasium, 
      Aerobics Hall, Yoga/Meditation Room, Swimming Pool &amp; Jacuzzi along with indoor &amp; 
      outdoor sports facilities,Creche,Chess & Carom Board, highlighted in our exclusive &nbsp;
       <u>
        <a routerlink="/amenities-and-specifications" href="/amenities"> 
         Amenities &amp; Specifications</a>
        </u> section. </p></div>
       
            </div>
            <div className="col-lg-6 line-pad aboutbrief pr50 text-justify-spl small-desk-design 
            mob-prn mob-pln">
              <div className="brief-content highlights123 pt50">
              <h5 className="ph-icons">
                <span>
                <img src="images/icon-9.png" style={{width:"8%"}}/></span> DESIGN </h5>
                <p> Each apartment is aesthetically designed, not only to provide a 
                spectacular view, but also to allow natural light, to stream in and maximum cross – ventilation. 
                Thoughtfully, each apartment incorporates ‘vastu’ principles as well as safety features, to promise 
                health and happiness to all residents of
                 <span className="hightlight1"> TUDA</span>. 
                The units boast effective carpet area utilization and developed as smart homes to be future-ready. </p>
                {/* <img className="about-projecthighlight-building" src="/images/img11.jpg" style={{marginLeft:"5%", width:"90%",height:"70vh"}}>
                  
                </img> */}
                <div className="brief-content highlights123 pt50">
          <h5 className="ph-icons">
            <span>
            <img src="	images/icon-8.png" style={{width:"8%"}}/>
            </span> GREEN LIVING </h5>
            <p> The project comprises more than 40% green cover on site, rain water harvesting, 
            sewage treatment plant, solar power for common area lighting, energy consumption monitoring through 
            metering usage etc. The building is developed with materials that optimize energy consumption. 
            </p></div>

                </div></div></div></div>
                <div className="line-one ltwhite-one"></div>
                <div className="line-two ltwhite-two"></div>
                <div className="line-three ltwhite-three"></div>
                <div className="line-four ltwhite-four"></div>
                <div className="line-five ltwhite-five"></div></section>


<section  className="page-section" style={{background:"#f1eaea", backgroundSize: "cover",
    height:"100%",padding:"4px",backgroundColor:"rgb(241, 234, 234)"}}>
      <div  className="line-container" style={{marginRight:"0px",padding:"0px"}}>
        <div  className="row dflex">
          <div  className="col-lg-12 line-pad aboutbrief pr50 text-justify-spl dflex mob-prn mob-pb20">
            <div  className="brief-content my-auto pr100 mob-prn">
              <div  className="title-gap">
                <h3  className="chead">
                  <img  src="/images/circle-head.png" style={{width:"4%",marginLeft:"2%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}/>
                    <span  className="black" > ULTRA-FINE LIVING  </span></h3></div>
                    <p  className="mob-pl30" style={{marginLeft:"4%",marginRight:"40%"}}>
                    <span className="hightlight1" >TUDA</span>&nbsp;
                                          raises the bar to offer you a style of living in line with international standards. 
                                         Addressing your need for exclusivity, each apartment is aesthetically designed, 
                                         not only to provide a spectacular view but also to allow natural light to stream in 
                                         and maximum cross-ventilation. The perfect planning is much in evidence in the superb 
                                         layout, quality of finish, spacious rooms, and top-of-the-line accessories. Thoughtfully, 
                                         each apartment incorporates ‘vastu’ principles as well as safety features, to promise 
                                         health and happiness to all residents of &nbsp;
                                         <span className="hightlight1">TUDA</span>.</p></div></div>
                     <div  className="col-lg-6">
                      <div  className="layout123">
                        <p  className="nopad mbn" style={{position:"relative;z-index:99"}}>
                          {/* <img  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/ultra-living.jpg" style={{width:"80%", height:"70vh",justifyContent: "flex-end",marginLeft:"20%"}}/> */}
                          </p></div></div></div></div>
                          <div  className="line-one ltgray-one"></div>
                          <div  className="line-two gray-two"></div>
                          <div  className="line-three gray-three"></div>
                          <div  className="line-four gray-four"></div>
                          <div  className="line-five gray-five"></div>
                          </section>

<section  className="page-section" style={{backgroundColor:"#f1eaea", backgroundSize: "cover",
    height:"100%",padding:"5px"}}>
      <div  className="line-container" style={{marginRight:"0px",padding:"0px"}}>
        <div  className="row dflex">
          <div  className="col-lg-6 line-pad aboutbrief pr50 text-justify-spl dflex mob-prn mob-pb20">
            <div  className="brief-content my-auto pr100 mob-prn">
              <div  className="title-gap">
                <h3  className="chead">
                  <img  src="/images/circle-head.png" style={{width:"8%",marginLeft:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}/>
                    <span  className="black" style={{marginLeft:"4px",marginRight:"10px"}}>WELCOME TO THE GOOD LIFE </span></h3></div>
                    <p  className="mob-pl30" style={{marginLeft:"8%",marginRight:"20%"}}> What's it you want from a perfect day?&nbsp; You 
                    want to work up a sweat in the gym and wash it off in a sauna? Or unleash your backhand
                     on an unsuspecting tennis fan and celebrate your win with a tall pick-me-up? Or play 
                     a noisy round of carroms with your kids and treat them to an unending sundae? Or laze 
                     about in a hammock with a great paperback and your spouse for company? Or catch up 
                     with office work in the morning and, still, host the most talked about party in the 
                     evening? With a multitude of thoughtful amenities, 
                     Clubhouse at <span  className="hightlight1">TUDA</span> takes you up close
                     to a lifestyle that lacks for nothing.</p></div></div>
                     <div  className="col-lg-6">
                      <div  className="layout123">
                        <p  className="nopad mbn" style={{position:"relative;z-index:99"}}>
                          <img  src="https://tudatowers-doc.s3.ap-south-1.amazonaws.com/images/tuda+3d+views/gallaery.jpeg" style={{width:"80%", height:"70vh",justifyContent: "flex-end",marginLeft:"20%"}}/></p></div></div></div></div>
                          <div  className="line-one ltgray-one"></div>
                          <div  className="line-two gray-two"></div>
                          <div  className="line-three gray-three"></div>
                          <div  className="line-four gray-four"></div>
                          <div  className="line-five gray-five"></div>
                          </section>

<section className="page-section ptn pb100 mob-pt50 about-bg-location" style={{backgroundColor:"rgb(241, 234, 234)"}}>
<div className="line-container"/>
<div className="row">
<div className="col-lg-6 line-pad text-justify-spl pt100">
<div className="title-gap">
  <h3 className="chead">
<img src="/images/circle-head.png" style={{width:"8%",marginLeft:"5%",backgroundColor:"rgb(241, 234, 234)",mixBlendMode:"multiply"}}/>
<span className="black" style={{marginLeft:"0%",marginRight:"0%"}}>AN ADDRESS TO CHERISH </span></h3></div>
<div className="my-auto">
<p className="pt20 pr50 mob-prn mob-pl30" style={{marginLeft:"8%",marginRight:"20%"}}>
 The chant 'location, location, location' with regard to real estate can never be overemphasised. 
 The location of a property is its most important quality. An important part of the value of 
 <span className="hightlight1">TUDA</span> is its enviable location – at Tirupati, 
 a stone's throw away from the administrative area of the green city, 
 in the very heart of Tirupati, the fast developing green city of Andhra Pradesh. </p></div></div></div></section>
 

                 <Footer/>
    </div>
  )
}

export default About;
